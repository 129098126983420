import * as Routes from '../../scripts/routes';
import { notification } from 'antd';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ShowNumberByOrgaoAssuntosController = ($http, $scope, $timeout, $filter) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.testeInicial           = 'Vai Dar Certo'
    vm.metodoInicial          = null
    vm.current_orgao_id       = null
    # VARIAVEIS PRIVATE


    # WATCHES


    # INIT SETUP
    init = () =>
      return


    # METHODS VIEW MODEL

    vm.changeStatus = (evento, assunto, tipo) ->
      evento.preventDefault()
      if assunto.id == 0
        result = await Swal.fire(
          icon:                'warning'
          title:               'Atenção!'
          html:                "Deseja #{if assunto.show then '<b>OCULTAR</b>' else '<b>EXIBIR</b>' } a numeração de todos os assuntos do tipo <b>#{tipo.nome}</b>?"
          confirmButtonText:   'SIM'
          confirmButtonColor:  '#075E54'
          cancelButtonText:    'NÃO'
          cancelButtonColor:  '#990000'
          showCancelButton:    true
          showLoaderOnConfirm: true
          allowOutsideClick:   false
          preConfirm: (_value) ->
            return new Promise((resolve,reject) ->
              $http.get(Routes.change_show_number_status_path({tipo_id: tipo.id, ativar: !assunto.show, orgao_id: vm.current_orgao_id, _options: true})).then ((result) ->
                if result.status == 200
                  assunto.show = !assunto.show
                  angular.forEach tipo.assuntos, (tp_assunto) ->
                    tp_assunto.show = assunto.show
                  resolve(true)
              ), (error) ->
                Swal.fire("Erro!", error.data.message, "error")
                reject(error)
              return
            )
        )
      else
        result = await Swal.fire(
          icon:                'warning'
          title:               'Atenção!'
          html:                "Deseja #{if assunto.show then '<b>OCULTAR</b>' else '<b>EXIBIR</b>' } o número do processo antes da aprovação?"
          confirmButtonText:   'SIM'
          confirmButtonColor:  '#075E54'
          cancelButtonText:    'NÃO'
          cancelButtonColor:  '#990000'
          showCancelButton:    true
          showLoaderOnConfirm: true
          allowOutsideClick:   false
          preConfirm: (_value) ->
            console.log(_value)
            return new Promise((resolve,reject) ->
              $http.get(Routes.change_show_number_status_path({assunto_id: assunto.id, ativar: !assunto.show, orgao_id: vm.current_orgao_id, _options: true})).then ((result) ->
                if result.status == 200
                  assunto.show = !assunto.show
                  tipo_assunto = $filter('filter')(tipo.assuntos, {id: 0}, true)[0]
                  all_true = true
                  angular.forEach tipo.assuntos, (assunto) ->
                    if assunto.show == false
                      if assunto.id != 0
                        all_true = false
                  tipo_assunto.show = all_true
                  resolve(true)
              ), (error) ->
                Swal.fire("Erro!", error.data.message, "error")
                reject(error)
              return
            )
        )
      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ShowNumberByOrgaoAssuntosController', ShowNumberByOrgaoAssuntosController

  # ANGULAR INJECT DEPENDENCIES
  ShowNumberByOrgaoAssuntosController.$inject = ['$http', '$scope', '$timeout', '$filter']

  # END BLOCK SCRIPT
  return




