### Init NG-APP ###
do ->
  'use strict'

  angular.module 'mainApp', [
    'ngSanitize'
    'LocalStorageModule'
  ]

  # END BLOCK SCRIPT
  return
