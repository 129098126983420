import * as Routes from '../../scripts/routes';
import { notification } from 'antd';
import sortable from "html5sortable/dist/html5sortable.es"

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ChecklistDocumentoNewEditController = ($http, $scope, $timeout) ->
    vm = @

    # CONSTANTS
    vm.TIPOS_DOCUMENTOS = ['livre', 'obrigatorio', 'variado']


    # VARIABLES VIEW MODEL
    vm.assuntos        = []
    vm.loadingAssuntos = false

    vm.showPasso1 = false
    vm.showPasso2 = false
    vm.showPasso3 = false
    vm.showPasso4 = false

    vm.check_list_item     = ''
    vm.check_list          = []
    vm.check_list_avancado = []
    vm.form                = {documentos: {check_list: null, check_list_avancado: null}}

    # VARIAVEIS PRIVATE


    # WATCHES

    # CHANGE SELECT TIPO PROCESSO
    $scope.$watch (->
      vm.form.tipo_id
    ), (current, _original) ->
      if current?
        id = current
        id = current.id if current?.id?
        setAssuntos(id)
      else
        vm.form.tipo_id    = null
        vm.form.assunto_id = null

    # WATCH CHECK LIST
    $scope.$watchCollection (->
      vm.check_list
    ), (current, original) ->
      if current.length > 1
        $timeout((->
          sortable('.sortable', {
            forcePlaceholderSize: true,
            placeholderClass:     'ph-class',
            hoverClass:           'bg-maroon yellow'
          })
        ), 200)

    # WATCH CHECK LIST AVANCADO
    $scope.$watchCollection (->
      vm.check_list_avancado
    ), (current, original) ->
      if current.length > 1
        $timeout((->
          sortable('.sortable', {
            forcePlaceholderSize: true,
            placeholderClass:     'ph-class',
            hoverClass:           'bg-maroon yellow'
          })
        ), 200)

    # IF INIT CHECK LIST
    $scope.$watch (->
      vm.form.documentos?.check_list
    ), (current, _original) ->
      if current != undefined
        vm.check_list = current if current? && current.length > 0

    # IF INIT CHECK LIST AVANCADO
    $scope.$watch (->
      vm.form.documentos?.check_list_avancado
    ), (current, _original) ->
      if current != undefined
        old_check_list =
          current.map (item)->
            item.quantidade = parseInt(item.quantidade)
            item
        vm.check_list_avancado = old_check_list if old_check_list? && old_check_list.length > 0


    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE
    setAssuntos = (tipo_id) ->
      if tipo_id? && tipo_id != ""
        vm.loadingAssuntos = true
        vm.assuntos        = [{nome: 'CARREGANDO...'}]
        $http.get(Routes.assuntos_processos_path(tipo_id), {cache: true}).then ((result) ->
          if(result.status == 200)
            vm.assuntos = result.data
            vm.form.assunto_id = {id: vm.form.assunto_id} if vm.form?.assunto_id
          else
            notification.warning({
              message:     'Atenção',
              description: 'Não foi possível processar o pedido.',
              duration:    10,
              placement:   'bottomRight'
            })
          vm.loadingAssuntos = false
        ), (error) ->
          vm.loadingAssuntos = false
          notification.error({
            message:     'Erro',
            description: error.data.message,
            duration:    10,
            placement:   'bottomRight'
          })
      return

    # METHODS VIEW MODEL
    vm.canPasso1 = ->
      return vm.showPasso1

    vm.canPasso2 = ->
      return vm.form.evento && vm.canPasso1()

    vm.canPasso3 = ->
      return vm.form.tipo_id && vm.canPasso2()

    vm.canPasso4 = ->
      return vm.form.assunto_id && vm.canPasso3()

    vm.canSubmit = ->
      return vm.form.evento &&
        vm.form.tipo_id &&
        vm.form.assunto_id &&
        vm.form.documentos?.tipo != undefined &&
        (vm.form.documentos?.tipo == vm.TIPOS_DOCUMENTOS[0] || (vm.check_list?.length > 0 || vm.check_list_avancado?.length > 0))

    vm.removeItemCheckList = (index) ->
      vm.check_list.splice(index, 1);

      return

    vm.removeItemCheckListAvancado = (index) ->
      vm.check_list_avancado.splice(index, 1)

      return

    vm.addCheckList = () ->
      return if vm.check_list_item == '' || !vm.check_list_item?
      unless vm.check_list.indexOf(vm.check_list_item) != -1
        vm.check_list.push(vm.check_list_item)
      vm.check_list_item = ''

      return

    vm.addCheckListAvancado = () ->
      return if vm.check_list_item == '' || !vm.check_list_item?
      unless vm.check_list_avancado.indexOf(vm.check_list_item) != -1
        vm.check_list_avancado.push({nome: vm.check_list_item, quantidade: 1, obrigatorio: true})
      vm.check_list_item = ''

      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ChecklistDocumentoNewEditController', ChecklistDocumentoNewEditController

  # ANGULAR INJECT DEPENDENCIES
  ChecklistDocumentoNewEditController.$inject = ['$http', '$scope', '$timeout']

  # END BLOCK SCRIPT
  return
