do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  CadastroUsuarioManifestanteController = ($http, $scope, $timeout) ->
    vm = @

    # CONSTANTS

    # VARIABLES VIEW MODEL
    vm.form            = {
      telefone_comercial:   null,
      telefone_celular:     null,
      email:                null,
      password:             null,
    }

    # VARIAVEIS PRIVATE

    # WATCHES


    # INIT SETUP
    init = () =>
      configInputMasks()
      return

    # METHODS PRIVATE

    configInputMasks = ->
      $timeout (->
        mask_email()
        mask_telefone('.mask-telefone-comercial')
        mask_telefone('.mask-telefone-celular')
        $('.select2-sexo').select2()
      ), 100


    # METHODS VIEW MODEL

    vm.canSubmit = ->
      # campos_cpf_cnpj = false
      # if vm.cnpjIds.includes(+vm.form.tipo_manifestante_id)
      #   campos_cpf_cnpj = vm.form.cnpj && vm.form.organizacao && vm.form.telefone_comercial
      # else
      #   campos_cpf_cnpj = vm.form.cpf && vm.form.nome && vm.form.sexo && vm.form.telefone_celular

      # campos_obrigatorios =
      #   vm.form.tipo_manifestante_id &&
      #     campos_cpf_cnpj &&
      #     vm.form.email && vm.form.cep && vm.form.uf && vm.form.cidade && vm.form.bairro && vm.form.endereco

      return true


    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'CadastroUsuarioManifestanteController', CadastroUsuarioManifestanteController

  # ANGULAR INJECT DEPENDENCIES
  CadastroUsuarioManifestanteController.$inject = ['$http', '$scope', '$timeout']

  # END BLOCK SCRIPT
  return
