import React, { useState } from 'react';
import _array from 'lodash/array'


export default ({default_lista = []}) => {
  const [lista, setLista] = useState(default_lista);
  const [item, setItem]   = useState('');

  const handleAdd = () => {
    if (item) {
      setLista([...lista, item])
      setItem('')
    }
  };

  const handleRemove = (item) => {
    setLista(oldLista => _array.without(oldLista, item))
  };

  return (
    <>
      <ul className='list-group'>
        <li className='list-group-item'>
          <div className='input-group'>
            <input type='text'
                   className='form-control'
                   placeholder='Novo Item'
                   value={item}
                   onChange={e => setItem(e.target.value)}/>
            <div className='input-group-append'>
              <button className='btn btn-outline-secondary'
                      type='button'
                      onClick={handleAdd}>
                <i className='fas fa-plus'></i>
              </button>
            </div>
          </div>
        </li>
        {lista.map((item, idx) => (
          <li key={idx} className='list-group-item d-flex justify-content-between align-items-center'>
            <div>
              <i className='fas fa-arrows-alt-v mr-2'></i>
              {item}
            </div>
            <div onClick={() => handleRemove(item)}><i className='fas fa-times-circle'></i></div>
          </li>)
        )}
      </ul>
    </>
  );
};
