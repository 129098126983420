import * as Routes from '../../scripts/routes';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ChecklistDocumentoController = ($http) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL


    # VARIAVEIS PRIVATE


    # WATCHES


    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE


    # METHODS VIEW MODEL

    vm.editItem = (hashid) ->
      console.log(hashid)
      return

    vm.modalDeletarItem = (fluxo_texto, hashid) ->
      Swal.fire(
        icon:                'warning'
        title:               'Atenção'
        text:                "Para deletar o seguinte fluxo: #{fluxo_texto}, é necessário digitar sua senha:"
        input:               'password'
        inputPlaceholder:    'digite aqui sua senha'
        confirmButtonText:   'Deletar'
        confirmButtonColor:  '#f5222d'
        showCloseButton:     true
        showCancelButton:    true
        showLoaderOnConfirm: true
        inputValidator: (value) => 'É necessário preencher a senha' unless value
        preConfirm: (inputValue) ->
          new Promise((resolve, _reject) ->
            $http.delete(Routes.checklist_documento_path(hashid), {params: {password: inputValue}}).then ((result) ->
              resolve(result.status == 200)

              return
            ), (error) ->
              Swal.fire("Erro!", error.data.message, "error")
            return
          )
        allowOutsideClick:   false
      ).then((result) ->
        if result.value == true
          Turbolinks.visit(Routes.checklist_documentos_path({message: 'Fluxo Removido'}))
      )

      return


    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ChecklistDocumentoController', ChecklistDocumentoController

  # ANGULAR INJECT DEPENDENCIES
  ChecklistDocumentoController.$inject = ['$http']

  # END BLOCK SCRIPT
  return
