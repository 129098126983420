import * as Routes from '../../scripts/routes';
import { notification } from 'antd';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  MudarStatusErrorsController = ($http) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.sortColumnName    = 'data'
    vm.reverseColumnName = false
    vm.status_errors     = []


    # VARIAVEIS PRIVATE


    # WATCHES


    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE


    # METHODS VIEW MODEL
    vm.sortColumn = (name) ->
      vm.reverseColumnName = if vm.sortColumnName == name then !vm.reverseColumnName else true;
      vm.sortColumnName    = name

      return

    vm.acaoItem = (item) ->
      item.loading_acao = true
      $http.post(Routes.acao_item_mudar_status_erros_path(), {
        acao:             item.acao,
        errors_ids:       item.errors_ids,
        processo_id:      item.spuv_id
        change_status_id: item.status_spue_id
      }).then ((result) ->
        if result.status == 200
          index = vm.status_errors.findIndex((record) => (record == item))
          vm.status_errors.splice(index, 1)
          notification.success({
            message:     "#{item.processo}",
            description: 'AÇÃO REALIZADA',
            duration:    3,
            top:         80,
            placement:   'topRight'
          })

        item.loading_acao = false
        return
      ), (error) ->
        notification.error({
          message:     'Erro',
          description: error.data.message,
          duration:    3
          placement:   'bottomRight'
        })
        item.loading_acao = false

      return


    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'MudarStatusErrorsController', MudarStatusErrorsController

  # ANGULAR INJECT DEPENDENCIES
  MudarStatusErrorsController.$inject = ['$http']

  # END BLOCK SCRIPT
  return
