import introJs from 'intro.js'

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  TourGeralCadastroInicialController = (currentUserId, localStorageService, $timeout, $compile, $scope) ->
    vm = @

    # CONSTANTS

    # VARIABLES VIEW MODEL
    vm.introJs             = null
    vm.currentLocalStorage = {
      prefix: 'TourGeral.',
      suffix: "CadastroInicial_#{currentUserId}",
      name: () -> "#{vm.currentLocalStorage.prefix}#{vm.currentLocalStorage.suffix}",
      value: () -> localStorageService.get(vm.currentLocalStorage.suffix),
      setValue: (bool) -> localStorageService.set(vm.currentLocalStorage.suffix, bool)
      init: () -> localStorageService.setPrefix(vm.currentLocalStorage.prefix)
    }

    # VARIAVEIS PRIVATE

    # WATCHES

    # INIT SETUP
    init = () =>
      initSetup()

      return

    # METHODS PRIVATE

    initSetup = ->
      vm.introJs = introJs()
      vm.currentLocalStorage.init()
      $timeout((-> initIntroJsTourGeral()), 500)

      return

    initIntroJsTourGeral = ->
      if vm.currentLocalStorage.value() != false
        setOptionsIntroJs()
        setCallbacksIntroJs()
        setStepsTourGeralIntroJs()
        setLocalStorageIntroJs(true)
        setStartIntroJs()

      return


    setOptionsIntroJs = ->
      vm.introJs.setOptions({doneLabel: 'Fechar', prevLabel: 'Voltar', nextLabel: 'Próximo', skipLabel: 'Pular'})

      return

    setCallbacksIntroJs = ->
      vm.introJs.onchange ->
        $timeout((->
          div_intro_html = $('.nao-ver-mais-intro')
          div_intro_html.html($compile(div_intro_html.html())($scope))
        ), 500)

      vm.introJs.onexit ->
        vm.introJs = null
        $('.toggle-sticky-top').addClass('sticky-top')

      return

    setLocalStorageIntroJs = (bool = true) ->
      if vm.currentLocalStorage.value() == null
        vm.currentLocalStorage.setValue(true)
      else if vm.currentLocalStorage.value()
        vm.currentLocalStorage.setValue(bool)

      return

    setStartIntroJs = () ->
      if vm.currentLocalStorage.value()
        vm.introJs.start()
        $('.toggle-sticky-top').removeClass('sticky-top')

      return

    setStepsTourGeralIntroJs = () ->
      link_cancel = "<span class='nao-ver-mais-intro'><small>(<a class='btn-link' ng-click='tgciCtrl.naoMostrarMaisTour()'><strong class='text-danger'>CLIQUE AQUI</strong></a> para <strong class='text-warning'>NÃO MOSTRAR</strong> o tour novamente)</small></span>"
      steps = [
        {
          intro: """
            <div class='text-center'>
              <div class='lead'>Olá, seja bem-vindo ao SPU Virtual, você está acessando pela primeira vez a área administrativa, vamos conhecê-la.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step2-menu-lateral',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui é a parte onde você verá os menus disponíveis para seu usuário. Como você ainda não fez seu cadastro inicial, você não possui menus liberados.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step3-menu-topo',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui são ações básicas que você pode realizar com seu usuário.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step4-menu-topo-user',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Ver detalhes da sua conta.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step5-menu-topo-notification',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Acompanhar notificações.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step6-menu-topo-sair',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Sair do Sistema.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step7-cadastro',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui temos um cadastro inicial para sabermos qual seu tipo de usuário.</div>
              <div class='lead'>Enquanto você não preencher o Formulário Inicial, você não poderá fazer nada no Sistema.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step8-formulario-inicial',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Esse é o formulário que você precisa preencher. Escolha corretamente o seu tipo de usuário, preencha as demais informações necessárias e envie seu formulário.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          intro: """
            <div class='text-center'>
              <div class='lead'>Pronto, agora você foi apresentado as funcionalidades iniciais do SPU Virtual.</div>
              #{link_cancel}
            </div>
          """
        }
      ]
      vm.introJs.setOptions({steps})

      return


    # METHODS VIEW MODEL
    vm.naoMostrarMaisTour = () ->
      setLocalStorageIntroJs(false)
      vm.introJs.exit()

      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'TourGeralCadastroInicialController', TourGeralCadastroInicialController

  # ANGULAR INJECT DEPENDENCIES
  TourGeralCadastroInicialController.$inject = ['currentUserId', 'localStorageService', '$timeout', '$compile',
    '$scope']

  # END BLOCK SCRIPT
  return
