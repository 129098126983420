import * as Routes from '../../scripts/routes';
import { notification } from 'antd';
import _isEmpty from 'lodash/isEmpty';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  AberturaProcessoController = ($http, $scope, $timeout, $window) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.form                = {
      tipo_id:      null,
      assunto_id:   null,
      movimentacao: {destino_orgao_id: null}
    }
    vm.assuntos            = []
    vm.orgaos              = []
    vm.allOrgaos           = []
    vm.loadingAssuntos     = false
    vm.showOverlayPassos   = true
    vm.workflow_documentos = {}
    vm.baseUploadProps     = {}
    vm.uploadProps         = {}
    vm.abrirWorkFlow       = true
    vm.uploadDocumentos    = false

    # VARIAVEIS PRIVATE
    corpoPresent = false

    # WATCHES

    # WATCH PROCESSO WORKFLOW
    $scope.$watchCollection (->
      [parseInt(vm.form.tipo_id), parseInt(vm.form.assunto_id?.id), parseInt(vm.form.movimentacao.destino_orgao_id?.id)]
    ), (current, original) ->
      checarDocumentosWorkflow({
        orgao_id:   current[2],
        tipo_id:    current[0],
        assunto_id: current[1]
      }) if current[0] && current[1] && current[2]

    # CHANGE SELECT TIPO PROCESSO
    $scope.$watch (->
      vm.form.tipo_id
    ), (current, _original) ->
      if current?
        id = current
        id = current.id if current?.id?
        setAssuntos(id)
      else
        vm.form.tipo_id    = null
        vm.form.assunto_id = null

      return

    # CHANGE BASE UPLOAD PROPS
    $scope.$watch (->
      vm.baseUploadProps
    ), (current, _original) ->
      vm.uploadProps = current if current?
      return

    # WATCH BLOCKED ORGAO BY ASSUNTO PROCESSO
    $scope.$watch (->
      vm.form.assunto_id
    ), (current, _original) ->
      if current?
        id = current
        id = current.id if current?.id?
        blockedOrgaosByAssuntoId(id)
      return

    # INIT SETUP
    init = () =>
      watchCorpo()

      removeShowOverlayPassos()

      return

    # METHODS PRIVATE
    setAssuntos = (tipo_id) ->
      if tipo_id? && tipo_id != ""
        vm.loadingAssuntos = true
        vm.assuntos        = [{nome: 'Carregando...'}]
        $http.get(Routes.assuntos_processos_path(tipo_id), {cache: true}).then ((result) ->
          if(result.status == 200)
            vm.assuntos = result.data
            vm.form.assunto_id = {id: vm.form.assunto_id} if vm.form?.assunto_id
          else
            notification.warning({
              message:     'Atenção',
              description: 'Não foi possível processar o pedido.',
              duration:    10,
              placement:   'bottomRight'
            })
          vm.loadingAssuntos = false
        ), (error) ->
          vm.loadingAssuntos = false
          notification.error({
            message:     'Erro',
            description: error.data.message,
            duration:    10,
            placement:   'bottomRight'
          })
      return

    blockedOrgaosByAssuntoId = (assunto_id) ->
      if assunto_id? && assunto_id != ""
        $http.get(Routes.orgaos_blocked_by_assunto_tipo_assunto_processo_path(assunto_id), {cache: true}).then ((result) ->
          if(result.status == 200)
            if vm.orgaos.length != vm.allOrgaos.length
              vm.orgaos = vm.allOrgaos
            blocked_ids = result.data
            vm.orgaos = vm.orgaos.filter (orgao) -> (!blocked_ids.includes(parseInt(orgao.id)))
          else
            notification.warning({
              message:     'Atenção',
              description: 'Não foi possível processar o pedido.',
              duration:    10,
              placement:   'bottomRight'
            })
          vm.loadingAssuntos = false
        ), (error) ->
          vm.loadingAssuntos = false
          console.log(error)
          notification.error({
            message:     'Erro',
            description: error.data.message || 'Não foi possível processar o pedido.',
            duration:    10,
            placement:   'bottomRight'
          })

      return

    watchCorpo = ->
      $('.summernote').on
        'summernote.init': () ->
          isEmpty = $(this).summernote('isEmpty')
          $scope.$apply -> corpoPresent = !isEmpty
          return
        'summernote.change': (_we, _contents, _$editable) ->
          isEmpty = $(this).summernote('isEmpty')
          $scope.$apply -> corpoPresent = !isEmpty
          return

      return

    removeShowOverlayPassos = ->
      $timeout (-> vm.showOverlayPassos = false), 1000
      return

    checarDocumentosWorkflow = (params) ->
      vm.abrirWorkFlow = true
      vm.uploadProps   = angular.copy(vm.baseUploadProps)
      $http.get(Routes.abertura_regras_documentos_processos_workflows_path(params), {cache: true}).then ((result) ->
        if(result.status == 200)
          vm.uploadProps = Object.assign(vm.uploadProps, {workflowDocumentos: ((_isEmpty(result.data) && {}) || (result.data))})

          $timeout ((->
            ReactRailsUJS.unmountComponents("#upload-documentos")
            ReactRailsUJS.mountComponents("#upload-documentos")
          )), 200

        else
          notification.warning({
            message:     'Atenção',
            description: 'Não foi possível processar o pedido.',
            duration:    10,
            placement:   'bottomRight'
          })
        vm.loadingAssuntos = false
      ), (error) ->
        vm.loadingAssuntos = false
        notification.error({
          message:     'Erro',
          description: error.data.message,
          duration:    10,
          placement:   'bottomRight'
        })
      return

    # METHODS VIEW MODEL

    vm.changeAbrirWorkFlow = (bool = true) ->
      vm.abrirWorkFlow = bool
      return

    vm.changeUploadDocumentos = (bool = true) ->
      vm.uploadDocumentos = bool
      return

    vm.canNotShowOrgao = ->
      return !vm.showOverlayPassos && (!vm.form.tipo_id || !vm.form.assunto_id)

    vm.canNotShowDescricao = ->
      return !vm.showOverlayPassos && (!vm.form.tipo_id || !vm.form.assunto_id || !vm.form.movimentacao.destino_orgao_id)

    vm.canNotShowUpload = ->
      return !vm.showOverlayPassos && (!vm.form.tipo_id || !vm.form.assunto_id || !vm.form.movimentacao.destino_orgao_id || !corpoPresent)

    vm.canSubmit = ->
      campos_obrigatorios =
        vm.form.tipo_id && vm.form.assunto_id && vm.form.movimentacao.destino_orgao_id && corpoPresent && vm.abrirWorkFlow && !vm.uploadDocumentos

      return campos_obrigatorios

    vm.submitForm = (event) ->
      event.preventDefault()
      modal = $('#modal-processo-abertura')
      modal.modal('show')
      $('.modal-backdrop').appendTo(modal.parent())

      return

    vm.message = (t) ->
      alert(t)

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'AberturaProcessoController', AberturaProcessoController

  # ANGULAR INJECT DEPENDENCIES
  AberturaProcessoController.$inject = ['$http', '$scope', '$timeout', '$window']

  # END BLOCK SCRIPT
  return
