import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Badge, Descriptions, Divider, Empty, Modal, notification, Skeleton, Timeline } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ScrollableAnchor, { configureAnchors, goToAnchor } from 'react-scrollable-anchor';
import ProcessoService from '../../services/ProcessoService';

export default ({hashid, em_aprovacao}) => {
  const [movimentacoes, setMovimentacoes]               = useState([]);
  const [loadingMovimentacoes, setLoadingMovimentacoes] = useState(true);
  const [showModalDetalhes, setShowModalDetalhes]       = useState(false);
  const [titleModal, setTitleModal]                     = useState(null);
  const [detalhes, setDetalhes]                         = useState(null);
  const [loadingDetalhes, setLoadingDetalhes]           = useState(false);
  const timelineId                                      = `timeline_${hashid}`;

  useEffect(() => {
    if (!em_aprovacao) {
      _fetchMovimentacoes(hashid);
    }
  }, []);

  const _fetchMovimentacoes = useCallback(
    async (processo_id) => {
      setLoadingMovimentacoes(true);
      try {
        const response  = await ProcessoService.spue_movimentacoes(processo_id);
        const {results} = response.data;
        setMovimentacoes(results);
        _initAnchor(results.length);
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
      setLoadingMovimentacoes(false);
    },
    []
  );

  const _initAnchor = (tamanho) => {
    let offset = -340;
    if (tamanho > 2) {
      offset = -600;
    }
    if (tamanho > 4) {
      offset = -980;
    }
    setTimeout(() => {
      configureAnchors({offset, scrollDuration: 800});
      goToAnchor(timelineId, false);
    }, 50);

  };

  const _fetchDetalhes = useCallback(
    async (processo_id, movimentacao_id) => {
      setLoadingDetalhes(true);
      setDetalhes(null);
      try {
        const response = await ProcessoService.spue_movimentacao_show(processo_id, movimentacao_id);
        const {result} = response.data;
        setDetalhes(result);
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
      setLoadingDetalhes(false);
    },
    []
  );

  const _clickMaisDetalhes = (processo_hashid, movimentacao_id, posicao) => {
    setTitleModal(`Detalhes Movimentação Nº${posicao}`);
    setShowModalDetalhes(true);
    _fetchDetalhes(processo_hashid, movimentacao_id);
  };

  const _handleCancel = () => {
    setShowModalDetalhes(false);
  };

  const TimelineCustom = () => {
    return (<>
      <Timeline pending={loadingMovimentacoes ? 'Aguarde...' : <strong style={{fontSize: '16px'}}>Fim</strong>}
                pendingDot={!loadingMovimentacoes && <ClockCircleOutlined style={{fontSize: '20px', color: 'red'}}/>}
                mode='alternate' reverse>
        {
          !loadingMovimentacoes && movimentacoes.map((mov, idx) => (
            <Timeline.Item key={mov.id}
                           dot={<Badge count={idx + 1} className={'badge-white'}/>}>
              <ScrollableAnchor id={timelineId}>
                <div className='mx-2'>
                  <div>
                    <em>de</em> <strong className={'text-uppercase text-muted'}>{mov.origem}</strong>{' '}
                    <em>para</em> <strong className={'text-uppercase text-muted'}>{mov.destino}</strong>
                  </div>
                  <div>
                    <em>ação</em> <strong className={'text-uppercase text-muted'}>Processo {mov.status}</strong>
                  </div>
                  <div>
                    <em>caixa atual</em> <strong className={'text-uppercase text-muted'}>{mov.caixa}</strong>
                  </div>
                  <div>
                    <em>data</em> <strong className={'text-uppercase text-muted'}>{mov.data}</strong>
                  </div>
                  <small><a onClick={() => _clickMaisDetalhes(mov.processo_hashid, mov.id, idx + 1)}>+ mais
                                                                                                     detalhes</a></small>
                </div>
              </ScrollableAnchor>
            </Timeline.Item>
          )) || (
            <Timeline.Item dot={<LoadingOutlined/>}/>
          )
        }
      </Timeline>
    </>);
  };

  return (
    <>
      <Divider dashed>Movimentações do Processo</Divider>

      {
        !em_aprovacao && (
          <>
            <TimelineCustom/>

            <Modal title={titleModal}
                   onCancel={_handleCancel}
                   footer={null}
                   centered
                   visible={showModalDetalhes}>
              <Skeleton active loading={loadingDetalhes}/>
              {detalhes && (
                <Descriptions bordered layout={'vertical'} size={'small'}>
                  {/*<Descriptions.Item label='Usuário Responsável' span={3}>{detalhes.usuario.nome}</Descriptions.Item>*/}
                  {/*<Descriptions.Item label='Usuário E-Mail' span={2}>{detalhes.usuario.email}</Descriptions.Item>*/}
                  <Descriptions.Item label='Despacho (descrição do que foi feito): ' span={3}>
                    {detalhes?.despacho && ReactHtmlParser(detalhes.despacho) || <p>Sem despacho adicionado</p>}
                  </Descriptions.Item>
                  {detalhes.telefones && !_.isEmpty(detalhes.telefones.setor) && ('setor' in detalhes.telefones) && (
                    <Descriptions.Item label='Telefones do Setor'
                                       span={3}>{detalhes.telefones.setor.join(', ')}</Descriptions.Item>
                  )}
                  {detalhes.telefones && !_.isEmpty(detalhes.telefones.orgao) && ('orgao' in detalhes.telefones) && (
                    <Descriptions.Item label='Telefones do Órgão'
                                       span={3}>{detalhes.telefones.orgao.join(', ')}</Descriptions.Item>
                  )}
                  {detalhes.email && ('setor' in detalhes.email) && (
                    <Descriptions.Item label='Email do Setor' span={3}>{detalhes.email.setor}</Descriptions.Item>
                  )}
                  {detalhes.email && ('orgao' in detalhes.email) && (
                    <Descriptions.Item label='Email do Órgão' span={3}>{detalhes.email.orgao}</Descriptions.Item>
                  )}
                </Descriptions>
              )}
            </Modal>
          </>
        ) || (
          <div className={'text-warning mb-4'}>
            <Empty description={
              <>
                <span className={'d-block'}>Atenção, o processo encontra-se em <em>Fase de Aprovação</em>.</span>
                <span className={'d-block'}>Assim que ele for aprovado pelo <em>Órgão Responsável</em>, você poderá acompanhar todas as <em>Movimentações do Process</em>o.</span>
              </>
            }/>
          </div>
        )
      }
    </>
  );
};
