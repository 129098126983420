do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  UserNewEditController = ($scope, $timeout) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.perfil         = null
    vm.showOrgaoInput = false


    # VARIAVEIS PRIVATE


    # WATCHES
    $scope.$watch (->
      vm.perfil
    ), (current, _original) ->
      if current? && ['admin', 'admin_orgao'].includes(current)
        vm.showOrgaoInput = true
        $timeout((-> $('.select2').select2()), 50)
      if current? && current == 'user'
        vm.showOrgaoInput = false


    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE


    # METHODS VIEW MODEL


    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'UserNewEditController', UserNewEditController

  # ANGULAR INJECT DEPENDENCIES
  UserNewEditController.$inject = ['$scope', '$timeout']

  # END BLOCK SCRIPT
  return
