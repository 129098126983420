import * as Routes from '../../scripts/routes';

angular.module('mainApp').service 'ManifestanteAcessosService', ['$http', '$filter', ($http, $filter) ->

  @lista = (current_user_id) ->
    $http.get(Routes.lista_users_manifestantes_path(current_user_id)).then ((result) ->
      if(result.status == 200)
        return {status: result.status, data: result.data}
    ), (error) ->
      handleError(error.data.message)

  @bloquear_desbloquear = (usuario_id) ->
    $http.get(Routes.bloquear_desbloquear_acesso_users_manifestantes_path(usuario_id)).then ((result) ->
      if(result.status == 200)
        return {status: result.status, data: result.data}
    ), (error) ->
      handleError(error.data.message)

  handleError = (message) ->
    if message != undefined and message.includes('PG::SyntaxError')
      message = 'Erro na base de dados'
    else if (message == undefined) || (message == null)
      message = 'Erro'

    Swal.fire(
      showCloseButton:    false,
      title:              'Atenção',
      text:               message,
      icon:               'warning',
      showCancelButton:   false,
      confirmButtonColor: '#33485d',
      confirmButtonText:  'Ok'
    )
    return

  return
]