// FIXME: A ORDEM DOS IMPORTS PODE INFLUENCIAR A COMPILACAO

// 1 - IMPORT RAILS
import Rails from '@rails/ujs';
import 'channels';
import Turbolinks from 'turbolinks';

// 2 - IMPORT LIBS JS
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';

// 3 - IMPORT STYLES
import '../stylesheets/application';
import '../stylesheets/_custom_ant.less';

// 4 - IMPORT APPS
import '../reactjs';
import '../angularjs';
import '../scripts';

// 5 - IMPORT IMAGES
require.context('../images', true);

// 6 - START LIBS FROM RAILS
Rails.start();
Turbolinks.start();
ReactRailsUJS.detectEvents();
