import React from 'react';
import { Menu, Dropdown } from 'antd';

export default (props) => {
  const menu = (
    <Menu>
      {!props.perfil_user && (
        <Menu.Item key='1'>
          <strong>Perfil: </strong>
          {props.roles}
        </Menu.Item>
      )}
      {!props.perfil_user && (
        <Menu.Divider/>
      )}
      <Menu.Item key='2'>
        <strong>Username: </strong>
        {props.username}
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item key='3'>
        <strong>Email: </strong>
        {props.email}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={'nav-link d-inline p-0'} title={'USUÁRIO'}>
        <Dropdown zIndex={3100} overlay={menu} trigger={['click']} placement={'bottomRight'}>
          <a className='btn btn-sm bg-white text-primary' onClick={e => e.preventDefault()}>
            {!props.perfil_user && (
              <span style={{
                fontSize:   '17px',
                position:   'relative',
                top:        '-3px',
                lineHeight: '0'
              }}>{props.roles.toUpperCase()}</span>
            )} <i className="fas fa-user-tag fa-2x"></i>
          </a>
        </Dropdown>
      </div>
    </>
  );
};
