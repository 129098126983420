import React from 'react';
import { Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import UsuariosService from '../../services/UsuariosService';

export default (id, hashid, email, callback = () => {}) => {
  const _handleOk = async () => {
    try {
      const response = await UsuariosService.deletar(hashid);
      if (response.status === 200) {
        const {id} = response.data;

        if (id) {
          Modal.success({
            content: `Usuário ID: ${id} deletado com sucesso`,
            centered: true,
          });
        }

        else {
          Modal.warning({
            content: response.data.message,
            centered: true,
          });
        }
        callback(true);
      }
    } catch (e) {
      Modal.error({
        title: 'Erro',
        content: `${e}`,
        centered: true,
      });
      callback(false);
    }
  };

  return Modal.confirm({
    title:    'Atenção',
    icon:     <ExclamationCircleOutlined/>,
    zIndex:   3100,
    content:  (<>
      <div>Deletar Usuário</div>
      <div>ID#{id}, {email}</div>
    </>),
    okText:   'Sim, Deletar',
    centered: true,
    onOk:     _handleOk,
    onCancel() {callback(false)},
  });
};
