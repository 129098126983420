import ptBr from 'antd/es/locale/pt_BR';
import React, { useState, useEffect, useCallback } from 'react';
import { ConfigProvider, notification, Select } from 'antd';
import LotacoesService from '../../services/LotacoesService';
import UsuariosService from '../../services/UsuariosService';

const {Option} = Select;

export default ({current_user_id = null, default_current_orgao_id = ''}) => {
  const [options, setOptions]             = useState([]);
  const [loading, setLoading]             = useState(false);
  const [defaultOption, setDefaultOption] = useState(null);

  const _fetchOrgaos = useCallback(
    async () => {
      setLoading(true);
      try {
        const response = await LotacoesService.orgaos_externos_ativos();
        if (response.status == 200) {
          const htmlOptions = response.data.map(ele => <Option key={ele.id}>{ele.sigla}</Option>);
          setOptions(htmlOptions);
          setDefaultOption(default_current_orgao_id.toString() || null);
        }
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
      setLoading(false);
    },
    [default_current_orgao_id]
  );

  useEffect(() => {
    _fetchOrgaos();
  }, [_fetchOrgaos]);


  const handleChange = async (value) => {
    if (defaultOption != value) {
      setDefaultOption(value);
      setLoading(true);
      try {
        const response = await UsuariosService.change_current_orgao(current_user_id, {user: {orgao_id: value}});
        if (response.status == 200) {
          Turbolinks.visit(location.toString());
        }
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
      setLoading(false);
    }
  };

  return (
    <div className={'nav-link d-inline p-0 mx-1'} title={'ÓRGÃO'}>
      <ConfigProvider locale={ptBr}>
        <Select placeholder={'Órgão'}
                value={defaultOption}
                style={{width: 100}}
                dropdownStyle={{zIndex: 3100}}
                loading={loading}
                size={'large'}
                showSearch
                optionFilterProp={'children'}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleChange}>
          {options}
        </Select>
      </ConfigProvider>
    </div>
  );
};
