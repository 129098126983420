import React, { useEffect, useState } from 'react';
import { version, Table } from 'antd';
import { ConfigProvider } from 'antd';
import ptBr from 'antd/es/locale/pt_BR';

const columns = [
  {
    title:            'E-mail',
    dataIndex:        'email',
    defaultSortOrder: 'descend',
  },
  {
    title:          <div className={'mt-1'}>Username</div>,
    dataIndex:      'username',
    key:            'username',
    filterMultiple: false,
    sorter:         (a, b) => a.username.length - b.username.length
  },
  {
    title:     'Ativo',
    dataIndex: 'activated',
    key:       'activated',
  },
];

export default ({users = []}) => {
  const [loading, setLoading]     = useState(true);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
      setDataTable(users);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [users]);

  return (
    <ConfigProvider locale={ptBr}>
      <Table
        loading={loading}
        title={() => <h1 className={'text-center'}>Users from Antd Version: {version}</h1>}
        dataSource={dataTable}
        rowKey={'id'}
        columns={columns}/>
    </ConfigProvider>
  );
};
