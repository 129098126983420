import introJs from 'intro.js'

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  TourGeralProcessoUsuarioController = (currentUserId, localStorageService, $timeout, $compile, $scope) ->
    vm = @

    # CONSTANTS

    # VARIABLES VIEW MODEL
    vm.introJs             = null
    vm.currentLocalStorage = {
      prefix: 'TourGeral.',
      suffix: "ProcessoUsuario_#{currentUserId}",
      name: () -> "#{vm.currentLocalStorage.prefix}#{vm.currentLocalStorage.suffix}",
      value: () -> localStorageService.get(vm.currentLocalStorage.suffix),
      setValue: (bool) -> localStorageService.set(vm.currentLocalStorage.suffix, bool)
      init: () -> localStorageService.setPrefix(vm.currentLocalStorage.prefix)
    }

    # VARIAVEIS PRIVATE

    # WATCHES

    # INIT SETUP
    init = () =>
      initSetup()

      return

    # METHODS PRIVATE

    initSetup = ->
      vm.introJs = introJs()
      vm.currentLocalStorage.init()
      $timeout((-> initIntroJsTourGeral()), 500)

      return

    initIntroJsTourGeral = ->
      if vm.currentLocalStorage.value() != false
        setOptionsIntroJs()
        setCallbacksIntroJs()
        setStepsTourGeralIntroJs()
        setLocalStorageIntroJs(true)
        setStartIntroJs()

      return


    setOptionsIntroJs = ->
      vm.introJs = introJs() unless vm.introJs
      vm.introJs.setOptions({doneLabel: 'Fechar', prevLabel: 'Voltar', nextLabel: 'Próximo', skipLabel: 'Pular'})

      return

    setCallbacksIntroJs = ->
      vm.introJs.onchange ->
        $timeout((->
          div_intro_html = $('.nao-ver-mais-intro')
          div_intro_html.html($compile(div_intro_html.html())($scope))
        ), 500)

      vm.introJs.onexit ->
        vm.introJs = null
        $('.toggle-sticky-top').addClass('sticky-top')

      return

    setLocalStorageIntroJs = (bool = true) ->
      if vm.currentLocalStorage.value() == null
        vm.currentLocalStorage.setValue(true)
      else if vm.currentLocalStorage.value()
        vm.currentLocalStorage.setValue(bool)

      return

    setStartIntroJs = (skipLocalStorage = false) ->
      if skipLocalStorage || vm.currentLocalStorage.value()
        vm.introJs.start()
        $('.toggle-sticky-top').removeClass('sticky-top')

      return

    setStepsTourGeralIntroJs = (showLinkCancel = true) ->
      link_cancel = (showLinkCancel && "<span class='nao-ver-mais-intro'><small>(<a class='btn-link' ng-click='tgpuCtrl.naoMostrarMaisTour()'><strong class='text-danger'>CLIQUE AQUI</strong></a> para <strong class='text-warning'>NÃO MOSTRAR</strong> o tour novamente)</small></span>") || ""
      steps = [
        {
          intro: """
            <div class='text-center'>
              <div class='lead'>Olá, antes de começar a usar a tela de Processos, vamos fazer um tour nas suas funcionalidades?</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-base',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Essa é sua região administrativa dos seus Processos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-acoes',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui estão as ações fundamentais para gerenciar seus Processos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-home',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Voltar para página inicial dos Processos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-recarregar',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Recarregar página atual dos Processos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-tour',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Rever tours da tela de processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-status',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui você ver uma descrição de todos os Status dos Processos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-video-ajuda',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Visualizar vídeos de ajuda com os Processos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-search',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Localizar um Processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-criar',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Criar um Rascunho ou Processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-limite',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui você acompanha seu limite e a quantidade atual de Processos abertos por dia.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step-geral-listagem',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Sua listagem de Processos e Rascunhos abertos.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          intro: """
            <div class='text-center'>
              <div class='lead'>Pronto, agora você está apto a abrir processos.</div>
              #{link_cancel}
            </div>
          """
        }
      ]
      vm.introJs.setOptions({steps})

      return


    # METHODS VIEW MODEL
    vm.naoMostrarMaisTour = () ->
      setLocalStorageIntroJs(false)
      vm.introJs.exit()

      return


    vm.showTourGeral = () ->
      setOptionsIntroJs()
      setStepsTourGeralIntroJs(false)
      setStartIntroJs(true)

      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'TourGeralProcessoUsuarioController', TourGeralProcessoUsuarioController

  # ANGULAR INJECT DEPENDENCIES
  TourGeralProcessoUsuarioController.$inject = ['currentUserId', 'localStorageService', '$timeout', '$compile',
    '$scope']

  # END BLOCK SCRIPT
  return
