### Load NG-APP ###
do ->
  'use strict'

  $(document).on 'turbolinks:load', ->
    angular.bootstrap document.body, ['mainApp']

    $(document).one 'turbolinks:load', ->
      $(".app-skeleton-load").hide()
      $(".app-skeleton-content").show()

    $(document).one 'turbolinks:click', ->
      $(".app-skeleton-content").hide()
      $(".app-skeleton-load").show()
      ReactRailsUJS.mountComponents(".app-skeleton-load")

  # END BLOCK SCRIPT
  return



### NgEnter ###
do ->
  'use strict'

  angular.module('mainApp').directive 'ngEnter', ->
    (scope, element, attrs) ->
      element.bind 'keydown keypress', (event) ->
        if event.which == 13
          scope.$apply ->
            scope.$eval attrs.ngEnter
            return
          event.preventDefault()
        return
      return

  # END BLOCK SCRIPT
  return

### Config Angular ###
do ->
  'use strict'

  configApp = ($httpProvider, $qProvider) ->
    $qProvider.errorOnUnhandledRejections false

    authToken = angular.element('meta[name="csrf-token"]').attr('content')
    $httpProvider.defaults.headers.common['X-CSRF-TOKEN'] = authToken

    return

  configApp.$inject = ['$httpProvider', '$qProvider']

  angular.module('mainApp').config configApp

  # END BLOCK SCRIPT
  return
