import * as Routes from '../../scripts/routes';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ManifestanteAcessosController = (ManifestanteAcessosService, $http, $scope, $timeout, $filter) ->
    vm = @
    appCtrl = $scope.$parent.appCtrl

    # CONSTANTS

    # VARIABLES VIEW MODEL
    vm.usuarios                 = []
    vm.loadingUsuarios          = true
    vm.sortColumnName           = 'tree'
    vm.reverseColumnName        = false
    vm.current_user_id          = null

    # VARIAVEIS PRIVATE

    # WATCHES

    $scope.$watch (->
      vm.current_user_id
    ), (current, _original) ->
      lista(current) if current?
      return

    # INIT SETUP
    init = () =>
      return

    # METHODS VIEW MODEL

    lista = (current_user_id) ->
      vm.usuarios = []

      if current_user_id
        Swal.fire(
          icon:                'info',
          title:               'Carregando Acessos',
          text:                'Por favor aguarde um momento...',
          showCloseButton:     false,
          showConfirmButton:   false,
          allowOutsideClick:   false,
          didOpen: () ->
            Swal.showLoading()
            return new Promise(->
              ManifestanteAcessosService.lista(current_user_id).then (result) ->
                if result.status == 200
                  vm.usuarios = result.data.usuarios
                  vm.loadingUsuarios = false
                  Swal.close()
            )
        )

    vm.sortColumn = (name) ->
      vm.reverseColumnName = if vm.sortColumnName == name then !vm.reverseColumnName else true;
      vm.sortColumnName    = name

    vm.bloquearDesbloquearAcesso = (usuario) ->
      if usuario.activated
        status = 'bloqueado'
      else
        status = 'desbloqueado'

      if usuario
        Swal.fire({
          icon:                'info',
          title:               'Você tem certeza?',
          text:                "O acesso deste usuário será #{status}!",
          showCloseButton:     false,
          showCancelButton:    true,
          confirmButtonColor:  appCtrl.swalConfirmColor,
          cancelButtonColor:   appCtrl.swalCloseColor,
          confirmButtonText:   'Continuar',
          cancelButtonText:    'Fechar',
          showLoaderOnConfirm: true,
          allowOutsideClick: () =>
            !Swal.isLoading()
          preConfirm: () =>
            ManifestanteAcessosService.bloquear_desbloquear(usuario.id).then (result) ->
              if result.status == 200
                user = $filter('filter')(vm.usuarios, {id: result.data.id}, true)[0]
                user.activated = result.data.activated

                if user.activated
                  message = 'Acesso desbloqueado com sucesso!'
                else
                  message = 'Acesso bloqueado com sucesso!'

                appCtrl.swalToast(icon: 'success', message: message)
        })
      return

    vm.editarAcesso = (usuario_id) ->
      return Routes.editar_acesso_users_manifestantes_path(usuario_id)

    # CALL INIT SETUP
    init()

    return

  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ManifestanteAcessosController', ManifestanteAcessosController

  # ANGULAR INJECT DEPENDENCIES
  ManifestanteAcessosController.$inject = ['ManifestanteAcessosService', '$http', '$scope', '$timeout', '$filter']

  # END BLOCK SCRIPT
  return
