import { Modal } from 'antd';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ProcessoUsuarioController = ($timeout) ->
    vm = @

    # CONSTANTS

    # VARIABLES VIEW MODEL

    # VARIAVEIS PRIVATE

    # WATCHES

    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE


    # METHODS VIEW MODEL
    vm.reloadPage = ->
      Turbolinks.visit(location.toString())

      return

    vm.alertLimiteProcessos = (_limite_texto) ->
      Modal.confirm({
        title:      "Atenção.",
        content:    'O limite de Abertura de Processos por dia foi atingido!',
        okText:     'Ok',
        cancelText: 'Cancelar',
        zIndex:     2000,
        centered:   true
      })

      return

    vm.waitAbrirProcesso = () ->
      $timeout((->
        Modal.confirm({
          title:      'Aguarde...',
          content:    'Isso pode demorar um pouco!',
          okText:     'Ok',
          cancelText: 'Cancelar',
          zIndex:     2000,
          centered:   true
        })
      ), 1000)

      return

    vm.showModalVideo = (video) ->
      modal = $('#modal-processos-video-ajuda')
      modal.modal('show')
      vm.modalTitle    = video.title
      vm.modalVideoSrc = video.src

      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ProcessoUsuarioController', ProcessoUsuarioController

  # ANGULAR INJECT DEPENDENCIES
  ProcessoUsuarioController.$inject = ['$timeout']

  # END BLOCK SCRIPT
  return
