import { API_BASE } from '../configs/Api';
import * as Routes from '../../scripts/routes';

export default class {
  static data_table = async (params) => {
    const options = {
      method: 'POST',
      url:    Routes.data_table_usuarios_path(),
      data:   params
    };

    return API_BASE(options);
  };

  static change_status = async (id) => {
    const options = {
      method: 'GET',
      url:    Routes.change_status_usuario_path(id)
    };

    return API_BASE(options);
  };

  static aprovar_cadastro = async (id) => {
    const options = {
      method: 'GET',
      url:    Routes.aprovar_cadastro_usuario_path(id)
    };

    return API_BASE(options);
  };

  static permissao_multiusuarios = async (id) => {
    const options = {
      method: 'GET',
      url:    Routes.multiusuarios_manifestante_usuario_path(id)
    };

    return API_BASE(options);
  };

  static deletar = async (id) => {
    const options = {
      method: 'DELETE',
      url:    Routes.usuario_path(id)
    };

    return API_BASE(options);
  };

  static change_current_orgao = async (id, params) => {
    const options = {
      method: 'POST',
      url:    Routes.change_current_orgao_usuario_path(id),
      data:   params
    };

    return API_BASE(options);
  };

  static resend_email_confirmation = async (hashid) => {
    const options = {
      method: 'GET',
      url:    Routes.resend_email_confirmation_usuario_path(hashid)
    };

    return API_BASE(options);
  };
}
