import React from 'react';
import { Skeleton } from 'antd';

export default () => {
  return (
    <>
      <div>
        <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
        <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
        <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
        <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
      </div>
    </>
  );
};
