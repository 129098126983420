import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { ConfigProvider, Modal, notification, Select } from 'antd';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';

import * as Routes from '../../../scripts/routes';
import DocumentoService from '../../services/DocumentoService';

const {Option} = Select;

registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginFileRename
);

const EXTENSOES_VALIDAS = {
  'doc':  'application/msword',
  'gif':  'image/gif',
  'png':  'image/png',
  'jpeg': 'image/jpeg',
  'jpg':  'image/jpeg',
  'odp':  'application/vnd.oasis.opendocument.presentation',
  'odt':  'application/vnd.oasis.opendocument.text',
  'pdf':  'application/pdf',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};

const Despacho = ({
                    defaultFiles = [],
                    form_authenticity_token = null,
                    nested_name_movimentacao = 'movimentacao_attributes',
                    maxFiles = 10,
                    maxFileSize = '5MB',
                    acceptedFileTypes = ['pdf', 'jpg', 'png', 'gif'],
                    workflowDocumentos = {}
                  }) => {
  const [files, setFiles]                       = useState(defaultFiles);
  const filePond                                = useRef(null);
  const [regrasDocumentos, setRegrasDocumentos] = useState({});
  const [options, setOptions]                   = useState([]);
  const [selectedOptions, setSelectedOptions]   = useState([]);
  const [selectedValues, setSelectedValues]     = useState([]);
  const scopeAngular                            = angular.element('[ng-controller="AberturaProcessoController as apCtrl"]').scope();

  if (!_.isEmpty(workflowDocumentos.check_list)) {
    maxFiles = workflowDocumentos.check_list.length;
  }

  useEffect(() => {
    if (workflowDocumentos.obrigatorio === 'true') {
      scopeAngular.$apply(() => scopeAngular.apCtrl.changeAbrirWorkFlow(false));
    }

    if (!_.isEmpty(workflowDocumentos.check_list)) {
      setRegrasDocumentos(workflowDocumentos);
      setSelectedValues(workflowDocumentos.check_list.map((item) => ({value: null, key: item})));
      if (!_.isEmpty(workflowDocumentos.check_list_values)) {
        setSelectedValues(workflowDocumentos.check_list.map((item, idx) => ({
          value: workflowDocumentos.check_list_values[idx],
          key:   item
        })));
      }
    }
  }, [workflowDocumentos, scopeAngular]);

  useEffect(() => {
    if (workflowDocumentos.obrigatorio && _.isEmpty(regrasDocumentos.check_list)) {
      if (files.length > 0) {
        scopeAngular.$apply(() => scopeAngular.apCtrl.changeAbrirWorkFlow(true));
      } else {
        scopeAngular.$apply(() => scopeAngular.apCtrl.changeAbrirWorkFlow(false));
      }
    }
    if (!_.isEmpty(workflowDocumentos.check_list)) {
      setOptions(files.map((ele) => ele.file?.name));
    }
  }, [workflowDocumentos, regrasDocumentos, scopeAngular, files]);

  useEffect(() => {
    if (!_.isEmpty(selectedValues)) {
      if (_.includes(_.map(selectedValues, 'value'), null)) {
        scopeAngular.$apply(() => scopeAngular.apCtrl.changeAbrirWorkFlow(false));
      } else {
        scopeAngular.$apply(() => scopeAngular.apCtrl.changeAbrirWorkFlow(true));
      }
    }
  }, [selectedValues, selectedOptions, scopeAngular]);

  const onAddFilesStart = (_file) => {
    if (scopeAngular.apCtrl.uploadDocumentos === false) {
      scopeAngular.$apply(() => scopeAngular.apCtrl.changeUploadDocumentos(true));
    }
  };

  const onProcessFilesFromDefaultState = () => {
    setFiles(filePond.current.getFiles());

    if (scopeAngular.apCtrl.uploadDocumentos === true) {
      scopeAngular.$apply(() => scopeAngular.apCtrl.changeUploadDocumentos(false));
    }
  };

  const onProcessFilesFromAddServer = (_error, _file) => {
    setFiles(filePond.current.getFiles());
  };

  const onProcessFilesFromRemoveServer = (_error, _doc) => {
    setFiles(filePond.current.getFiles());
    let newArray = selectedValues;
    newArray     = newArray.map(item => ({...item, value: null}));
    setSelectedValues(newArray);
    setSelectedOptions([]);
  };

  const FilesInputsHidden = () => (
    <span className={'hidden-inputs-files'}>
      {files.filter(item => item.serverId).map((item, index) => {
        let idx = index;
        if (!_.isEmpty(selectedValues) && !_.includes(_.map(selectedValues, 'value'), null)) {
          idx = _.findIndex(selectedValues, {value: item.filename});
        }

        return (
          <input type={'hidden'}
                 key={index}
                 name={`processo[${nested_name_movimentacao}][documentos_attributes][${idx}][${item?.file?.hashid ? 'id' : 'arquivo_cache'}]`}
                 value={item.serverId} />
        );
      })}
    </span>
  );

  const removeFile = async (hashid) => {
    try {
      const response = await DocumentoService.deletar(hashid);
      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
      return false;
    }
  };

  const modalRemoveFile = (file, filename) => (
    new Promise((resolve, _reject) => (
      Modal.confirm({
        title:    'Atenção',
        icon:     <ExclamationCircleOutlined />,
        zIndex:   3100,
        content:  (<>
          <div>Deletar Arquivo: <span className='text-danger'>{filename}</span></div>
        </>),
        okText:   'Sim, Deletar',
        centered: true,
        onOk:     async () => {
          const result = await removeFile(file.hashid);

          resolve(result);
        }
      })
    )).then(result => result)
  );

  const beforeRemoveFile = (item) => {
    if (item.file?.hashid) {
      return modalRemoveFile(item.file, item.filename);
    }
  };

  const onChangeSelect = (item, idx) => {
    setSelectedOptions([...selectedOptions, item]);
    let newArray  = selectedValues;
    newArray[idx] = {...newArray[idx], value: item};
    setSelectedValues(newArray);
  };

  const contentHtml = `
    <div>
      <div style='font-size: 3em;' class='text-primary'>
        <i class='fas fa-download'></i>
      </div>
      <div class='text-muted' style='font-size: 18px'>
        <div>Arraste o(s) arquivo(s) para essa região ou</div>
        <div class='filepond--label-action'>clique aqui para anexar manualmente o(s) arquivo(s)</div>
      </div>
      <div class='text-muted' style='font-size: 12px'>
         limite de arquivos:
         <strong class='text-danger'>${maxFiles}</strong>
      </div>
      <div class='text-muted' style='font-size: 12px'>
         limite de tamanho por arquivo:
         <strong class='text-danger'>${maxFileSize}</strong>
      </div>
      <div class='text-muted' style='font-size: 12px'>
          formatos permitidos:
          <strong class='text-danger'>${acceptedFileTypes.join()}</strong>
      </div>
    </div>
  `;

  let filteredOptions = options.filter(o => !selectedOptions.includes(o));
  if (!_.isEmpty(workflowDocumentos.check_list_values)) {
    filteredOptions = [];
    if (files.length === 0) {
      workflowDocumentos.check_list_values = [];
    }
  }

  return (
    <>
      <div className={'mt-3'}>
        {workflowDocumentos.obrigatorio && _.isEmpty(regrasDocumentos.check_list) && (
          <div className={'text-center mb-3'}>
            <strong className={'text-warning'}>
              Atenção, é obrigatório anexar pelo menos um documento devido ao fluxo escolhido acima.
            </strong>
          </div>
        )}

        {!_.isEmpty(regrasDocumentos.check_list) && (
          <>
            <div className={'text-center mb-3'}>
              <strong className={'text-warning'}>
                Atenção, o fluxo acima exige a inserção de arquivos e relacionamento dos documentos.
                Enquanto você não fizer isso, a abertura do processo estará bloqueada.
              </strong>
            </div>
            <table className='table table-bordered table-sm'>
              <thead>
                <tr>
                  <th className={'text-center'}>-</th>
                  <th>DOCUMENTOS OBRIGATÓRIOS</th>
                  <th className={'text-center'}>RELACIONAR ARQUIVO</th>
                </tr>
              </thead>
              <tbody>
                {regrasDocumentos.check_list.map((item, idx) => {
                  return (<tr key={idx}>
                    <td className={'text-uppercase text-center'}>
                      {_.isEmpty(selectedValues[idx].value) ? <CloseCircleFilled className={'text-danger'} /> :
                        <CheckCircleFilled className={'text-primary'} />}</td>
                    <td className={'text-uppercase'}>
                      <em className={'text-warning'}>{item}</em>
                    </td>
                    <td>
                      <ConfigProvider
                        renderEmpty={() => {
                          if (!_.isEmpty(workflowDocumentos.check_list_values)) {
                            return (
                              <div className={'text-center'}>
                                atenção, para editar remova todos os arquivos
                              </div>
                            );
                          } else {
                            return (
                              <div className={'text-center'}>
                                por favor, faça upload do arquivo na região abaixo e depois relacione aqui
                              </div>
                            );
                          }
                        }}>
                        <Select size={'small'}
                                style={{width: '100%'}}
                                onChange={(item) => onChangeSelect(item, idx)}
                                value={selectedValues[idx].value}
                                placeholder={'SELECIONE O ARQUIVO'}>
                          {filteredOptions.map((item, idx) => <Option key={idx} value={item}>{item}</Option>)}
                        </Select>
                      </ConfigProvider>
                    </td>
                  </tr>);
                })}
              </tbody>
            </table>
          </>
        )}

        <div className={'text-center'}>
          <label>
            <strong>
              Total de Arquivos <i className={'fa fa-paperclip'}></i>: <span
              className='text-danger'>{files.length}</span>
            </strong>
          </label>
        </div>

        <FilePond
          ref={filePond}
          files={files}
          allowMultiple
          maxFiles={maxFiles}
          maxParallelUploads={1}
          allowReorder
          credits={false}
          maxFileSize={maxFileSize}
          labelMaxFileSizeExceeded={'Arquivo muito longo'}
          labelMaxFileSize={'O tamanho máximo é {filesize}'}
          allowFileSizeValidation
          labelFileTypeNotAllowed={'Formato de arquivo inválido'}
          labelIdle={contentHtml}
          fileValidateTypeLabelExpectedTypes={'Formatos permitidos: {allTypes}'}
          fileValidateTypeLabelExpectedTypesMap={_.invert(EXTENSOES_VALIDAS)}
          acceptedFileTypes={Object.values(_.pick(EXTENSOES_VALIDAS, acceptedFileTypes))}
          labelFileProcessing={'Processando...'}
          labelFileLoadError={'Problemas na leitura arquivo'}
          labelFileProcessingError={'Problemas no upload do arquivo'}
          labelFileProcessingComplete={'Arquivo enviado'}
          labelTapToCancel={'toque para cancelar'}
          labelTapToRetry={'toque para reenviar'}
          labelTapToUndo={'toque para cancelar'}
          onaddfilestart={onAddFilesStart}
          onprocessfiles={onProcessFilesFromDefaultState}
          onprocessfile={onProcessFilesFromAddServer}
          onremovefile={onProcessFilesFromRemoveServer}
          beforeRemoveFile={beforeRemoveFile}
          fileRenameFunction={(file) => {
            return `${_.truncate(_.deburr(_.trim(file.basename)), {'length': 200})}_${moment().format('YYYYMMDDHHmmss')}${file.extension}`;
          }}
          server={{
            process: {
              url:     Routes.documentos_path(),
              headers: {'X-CSRF-Token': form_authenticity_token}
            },
            revert:  {
              url:     Routes.remove_file_tmp_documentos_path(),
              headers: {'X-CSRF-Token': form_authenticity_token}
            }
          }}
        />

        <FilesInputsHidden />
      </div>
    </>
  );
};

export default React.memo(Despacho);
