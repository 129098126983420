import ptBr from 'antd/es/locale/pt_BR';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, ConfigProvider, Divider, notification, Table, Tag, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ProcessoService from '../../services/ProcessoService';
import fileExtension from 'file-extension';
import ScrollableAnchor, { goToAnchor, configureAnchors } from 'react-scrollable-anchor';

export default ({hashid}) => {
  const [loading, setLoading]   = useState(false);
  const [arquivos, setArquivos] = useState([]);
  const documentosId            = `documentos_${hashid}`;

  const _fetchDocumentos = useCallback(
    async (processo_id) => {
      setLoading(true);
      try {
        const response  = await ProcessoService.documentos(processo_id);
        const {results} = response.data;
        setArquivos(results);
        _initAnchor(results.length);
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
      setLoading(false);
    },
    [],
  );

  useEffect(() => {
    _fetchDocumentos(hashid);
  }, [_fetchDocumentos]);

  const _initAnchor = (tamanho) => {
    let offset = -340;

    setTimeout(() => {
      configureAnchors({offset, scrollDuration: 800});
      goToAnchor(documentosId, false);
    }, 50);
  };

  const columns = [
    {
      title:     'Arquivo',
      dataIndex: 'name',
      width:     100
    },
    {
      title:     'Tipo',
      dataIndex: 'type',
      align:     'center',
      render:    (text, record) => (
        <div>.{fileExtension(record.name)}</div>
      )
    },
    {
      title:     'Tamanho',
      align:     'center',
      dataIndex: 'size',
    },
    {
      title:     'Data de Envio',
      align:     'center',
      dataIndex: 'created_at',
    },
    {
      title:  'Ações',
      key:    'action',
      align:  'center',
      render: (text, record) => {
        return (
          <Tooltip title={'Baixar Documento'} placement={'bottom'}>
            <Button href={record.url} target={'blank'} type={'dashed'} icon={<DownloadOutlined/>} size={'small'}/>
          </Tooltip>
        );
      }
    },
  ];

  return (
    <>
      <Divider dashed>Arquivos Anexados</Divider>

      <ScrollableAnchor id={documentosId}>
        <div className='container-fluid mb-4'>
          <ConfigProvider locale={ptBr}>
            <Table scroll={{x: 200}} bordered loading={loading} pagination={false} columns={columns}
                   dataSource={arquivos} size='small'/>
          </ConfigProvider>
        </div>
      </ScrollableAnchor>
    </>
  );
};
