import * as Routes from '../../scripts/routes';
import { notification } from 'antd';
import sortable from "html5sortable/dist/html5sortable.es"
import _isEmpty from 'lodash/isEmpty';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ProcessoWorkflowNewEditController = ($http, $scope, $timeout) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.assuntos        = []
    vm.loadingAssuntos = false
    vm.loadingTipos    = false

    vm.showPasso1 = false
    vm.showPasso2 = false
    vm.showPasso3 = false
    vm.showPasso4 = false
    vm.showPasso5 = false
    vm.showPasso6 = false

    vm.check_list_item            = ''
    vm.check_list                 = []
    vm.check_list_avancado        = []
    vm.form                       = {documentos: {check_list: null, check_list_avancado: null}}    
    vm.setores_sem_telefones      = []
    vm.current_setor_sem_telefone = false
    vm.route_telefone_setor       = null
    vm.checklist_documentos       = {}
    vm.tiposProcesso              = []

    # VARIAVEIS PRIVATE


    # WATCHES

    # CHANGE SELECT TIPO PROCESSO
    $scope.$watch (->
      vm.form.tipo_id
    ), (current, _original) ->
      if current?
        id = current
        id = current.id if current?.id?
        setAssuntos(id)
      else
        vm.form.tipo_id    = null
        vm.form.assunto_id = null

    # WATCH CHECK LIST
    $scope.$watchCollection (->
      vm.check_list
    ), (current, _original) ->
      if current.length > 1
        $timeout((->
          sortable('.sortable', {
            forcePlaceholderSize: true,
            placeholderClass:     'ph-class',
            hoverClass:           'bg-maroon yellow'
          })
        ), 200)

    # IF INIT CHECK LIST
    $scope.$watch (->
      vm.form.documentos?.check_list
    ), (current, _original) ->
      if current != undefined        
        vm.check_list = current if current? && current.length > 0        
        #alert(JSON.stringify(current))

    # IF INIT CHECK LIST AVANCADO
    $scope.$watch (->
      vm.form.documentos?.check_list_avancado
    ), (current, _original) ->
      if current != undefined      
        old_check_list =
          current.map (item)->
            item.quantidade = parseInt(item.quantidade)
            item
        vm.check_list_avancado = old_check_list if old_check_list? && old_check_list.length > 0            

    # WATCH CHECK LIST AVANCADO
    $scope.$watchCollection (->
      vm.check_list_avancado
    ), (current, original) ->
      if current.length > 1
        $timeout((->
          sortable('.sortable', {
            forcePlaceholderSize: true,
            placeholderClass:     'ph-class',
            hoverClass:           'bg-maroon yellow'
          })
        ), 200)        

    # # CHANGE SELECT TIPO PROCESSO
    # $scope.$watch (->
    #   vm.form.documentos?.check_list
    # ), (current, _original) ->
    #   if current != undefined
    #     old_check_list = JSON.parse(current)
    #     vm.check_list = old_check_list if old_check_list? && old_check_list.length > 0


    # CHANGE SELECT SETOR ID
    $scope.$watch (->
      vm.form.setor_id
    ), (current, _original) ->
      if current?
        checkSetorSemTelefone(current)
        setRouteTelefoneSetor(current)

    # CHANGE TIPO AND ASSUNTO ID
    $scope.$watchCollection (->
      [parseInt(vm.form.tipo_id), parseInt(vm.form.assunto_id?.id)]
    ), (current, _original) ->
      if current[0] && current[1]
        checarChecklistDocumentos({
          tipo_id:    current[0],
          assunto_id: current[1]
        })
        $timeout((-> $('#processo_workflow_documentos_obrigatorio').select2()), 50)

    # # CHANGE SISTEMA
    $scope.$watch (->
      vm.form.sistema
    ), (current, _original) ->
      if current?
        vm.form.tipo_id = null
        checkTiposProcesso(current)

    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE
    checarChecklistDocumentos = (params) ->
      vm.check_list = []
      $http.get(Routes.abertura_regras_documentos_checklist_documentos_path(params), {cache: true}).then ((result) ->
        if(result.status == 200)
          vm.checklist_documentos = result.data.check_list

          $timeout((->
            sortable('.sortable', {
              forcePlaceholderSize: true,
              placeholderClass:     'ph-class',
              hoverClass:           'bg-maroon yellow'
            })
          ), 200)
      ), (_error) ->
        vm.checklist_documentos = {}

      return

    checkTiposProcesso = (params) ->
      vm.loadingTipos   = true
      vm.tiposProcesso  = [['CARREGANDO...', 99999]]
      $http.get(Routes.get_tipos_processos_workflows_path({sistema: params}), {cache: true}).then ((result) ->
        if(result.status == 200)
          vm.tiposProcesso = result.data.tipos
        else
          notification.warning({
            message:     'Atenção',
            description: 'Não foi possível processar o pedido.',
            duration:    10,
            placement:   'bottomRight'
          })
        vm.loadingTipos = false
      ), (_error) ->
        vm.loadingTipos = false
        notification.error({
          message:     'Erro',
          description: error.data.message,
          duration:    10,
          placement:   'bottomRight'
        })
      return

    setRouteTelefoneSetor = (setor_id) ->
      vm.route_telefone_setor = Routes.telefones_setores_path(filter: setor_id)
      return

    setAssuntos = (tipo_id) ->
      if tipo_id? && tipo_id != ""
        vm.loadingAssuntos = true
        vm.assuntos        = [{nome: 'CARREGANDO...'}]
        $http.get(Routes.assuntos_processos_path({'tipo_id': tipo_id, 'sistema': vm.form.sistema}), {cache: true}).then ((result) ->
          if(result.status == 200)
            vm.assuntos = result.data
            vm.form.assunto_id = {id: vm.form.assunto_id} if vm.form?.assunto_id
          else
            notification.warning({
              message:     'Atenção',
              description: 'Não foi possível processar o pedido.',
              duration:    10,
              placement:   'bottomRight'
            })
          vm.loadingAssuntos = false
        ), (error) ->
          vm.loadingAssuntos = false
          notification.error({
            message:     'Erro',
            description: error.data.message,
            duration:    10,
            placement:   'bottomRight'
          })
      return


    checkSetorSemTelefone = (setor_id) ->
      vm.current_setor_sem_telefone = parseInt(setor_id) in vm.setores_sem_telefones
      vm.current_setor_sem_telefone = false if vm.form.sistema == 'true'
      return

    # METHODS VIEW MODEL
    vm.canPasso1 = ->
      return vm.showPasso1

    vm.canPasso2 = ->
      return vm.form.sistema && vm.canPasso1()

    vm.canPasso3 = ->
      return vm.form.evento && vm.canPasso2()

    vm.canPasso4 = ->
      return vm.form.tipo_id && vm.canPasso3()

    vm.canPasso5 = ->
      return vm.form.assunto_id && vm.canPasso4()

    vm.canPasso6 = ->
      return vm.form.setor_id && (!vm.current_setor_sem_telefone || vm.form.sistema == 'true') && vm.canPasso5()

    vm.canSubmit = ->
      return vm.form.evento &&
        vm.form.tipo_id &&
        vm.form.assunto_id &&
        vm.form.setor_id &&
        (!vm.current_setor_sem_telefone || vm.form.sistema == 'true') &&
        (!_isEmpty(vm.checklist_documentos) || (vm.form.documentos && vm.form.documentos.obrigatorio != undefined) || vm.check_list_avancado?.length > 0)

    vm.removeItem = (index) ->
      vm.check_list.splice(index, 1);
      return

    vm.addCheckList = () ->
      return if vm.check_list_item == '' || !vm.check_list_item?
      unless vm.check_list.indexOf(vm.check_list_item) != -1
        vm.check_list.push(vm.check_list_item)
      vm.check_list_item = ''

      return

    vm.removeItemCheckListAvancado = (index) ->
      vm.check_list_avancado.splice(index, 1)

      return

    vm.addCheckListAvancado = () ->
      return if vm.check_list_item == '' || !vm.check_list_item?
      unless vm.check_list_avancado.indexOf(vm.check_list_item) != -1
        vm.check_list_avancado.push({nome: vm.check_list_item, quantidade: 1, obrigatorio: true})
      vm.check_list_item = ''

      return      



    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ProcessoWorkflowNewEditController', ProcessoWorkflowNewEditController

  # ANGULAR INJECT DEPENDENCIES
  ProcessoWorkflowNewEditController.$inject = ['$http', '$scope', '$timeout']

  # END BLOCK SCRIPT
  return
