import React, { useState } from 'react';
import { Button } from 'antd';
import aprovarCadastroUsuario from '../Modal/aprovarCadastroUsuario';
import deletarUsuario from '../Modal/deletarUsuario';
import * as Routes from '../../../scripts/routes';

export default ({type, texto, id, hashid, email}) => {
  const [loading, setLoading] = useState(false);

  const _callbackClick = (result) => {
    if (result) {
      let message = '';
      if (type == 'aprovar') { message = `Usuário #${id} (${email}) - Cadastro Aprovado.`; }
      if (type == 'deletar') { message = `Usuário #${id} (${email}) - Usuário Removido.`; }
      Turbolinks.visit(Routes.usuarios_path({message: message}));
    }
    setLoading(false);
  };

  const _handleClick = () => {
    setLoading(true);
    if (type == 'aprovar') { aprovarCadastroUsuario(id, hashid, email, _callbackClick); }
    if (type == 'deletar') { deletarUsuario(id, hashid, email, _callbackClick); }
  };

  return (
    <>
      <Button size='large'
              type='dashed'
              loading={loading}
              onClick={_handleClick}>{texto || 'btn sem texto'}</Button>
    </>
  );
};
