import React from 'react';
import { Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import UsuariosService from '../../services/UsuariosService';

export default (id, hashid, email, callback = () => {}) => {
  const _handleOk = async () => {
    try {
      const response = await UsuariosService.aprovar_cadastro(hashid);
      if (response.status === 200) {
        const {id, state} = response.data;
        notification.success({
          message:     `ID: ${id} - Sucesso`,
          description: `Fase alterado para - ${state}`,
          duration:    10,
          placement:   'bottomRight'
        });
        callback(true);
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
      callback(false);
    }
  };

  return Modal.confirm({
    title:    'Atenção',
    zIndex:   3100,
    icon:     <ExclamationCircleOutlined/>,
    content:  (<>
      <div>Aprovar Cadastro:</div>
      <div>ID#{id}, {email}</div>
    </>),
    okText:   'Sim, Aprovar',
    centered: true,
    onOk:     _handleOk,
    onCancel() {callback(false);},
  });
};
