import * as Routes from '../../scripts/routes';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  ApplicationController = ($timeout, $scope, $compile) ->
    vm = @

    vm.swalConfirmColor = '#33485d'
    vm.swalCloseColor = '#e74c3c'

    ### CONSTANTS ###

    ### VARIABLES VIEW MODEL ###

    ### VARIAVEIS PRIVATE ###

    ### WATCHES ###

    # INIT SETUP
    init = ()=>
      configCompileModalBody()
      configSweetAlert()
      return

    ### METHODS PRIVATE ###
    configCompileModalBody = () ->
      $scope.$on 'CompileModalBody', (event, data) ->
        $scope.$apply ->
          $(data.id).find('.modal-body').html($compile(data.body)($scope))
        return

    configSweetAlert = () ->
      $scope.$on 'SweetAlertToast', (event, data) ->
        $scope.$apply ->
          vm.swalToast(data)
          document.location.reload(true) if data.reload
        return

    ### METHODS VIEW MODEL ###

    vm.swalToast = (data) ->
      if data.reload
        timer = 3000
      else
        timer = false

      Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: !data.reload,
        timer: timer,
        timerProgressBar: true,
        didOpen: (toast) =>
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
      })

      Toast.fire({
        icon: data.icon,
        title: data.message
      })

      $timeout (->
        $('.swal2-container').css("z-index", 100000 );
        return
      ), 10

      return

    vm.swalConfirm = () -> # TODO
      return

    vm.reactMountComponent = (id) ->
      $timeout (->
        ReactRailsUJS.mountComponents("##{id}")
        return
      ), 10

    vm.showAlertTelefoneOrgao = (setor_id) ->
      if setor_id?
        result = await Swal.fire
          title:              '<div>ATENÇÃO ADMIN ÓRGÃO</div>'
          icon:               'info'
          html:               "<div class='lead'>Seu <span class='text-danger'>órgão</span> não possui telefones cadastrados. Cadastre o telefone agora.</div>"
          confirmButtonText:  'Cadastrar Telefone do Órgão'
          confirmButtonColor: '#199B91'
          showCloseButton:    true
          showCancelButton:   true

        if result.isConfirmed
          Turbolinks.visit(Routes.telefones_setores_path(filter: setor_id))
      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'ApplicationController', ApplicationController

  # ANGULAR INJECT DEPENDENCIES
  ApplicationController.$inject = ['$timeout', '$scope', '$compile']

  # END BLOCK SCRIPT
  return
