import React, { useState, useEffect, useCallback } from 'react';
import { Divider } from 'antd';
import ItemCheckList from './ItemCheckList';

const CheckList = ({workflowDocumentos, defaultProps}) => {
  // CONFIG CONST
  const {_tipo, check_list: checkList} = workflowDocumentos;
  const itensCheckList                 =
          checkList.map((item, idx) => {
            item.ordem = idx;

            return item;
          });

  // CONFIG DEFAULT STATES
  const [validateItensCheckList, setValidateItensCheckList] = useState(false);
  const [itensValidate, setItensValidate]                   =
          useState(itensCheckList.filter((ele) => ele.obrigatorio === 'true').map((ele) => ({
            ordem: ele.ordem, status: false
          })));

  // CONFIG SCOPE ANGULAR
  const scopeAngular = angular.element('[ng-controller="AberturaProcessoController as apCtrl"]').scope();

  // EFFECT TO SET STATE ALL ITENS OK
  useEffect(() => {
    setValidateItensCheckList(!itensValidate.map((ele) => ele.status).includes(false));

    return () => {};
  }, [itensValidate]);

  // EFFECT CHECK ALL ITENS TRUE
  useEffect(() => {
    scopeAngular.$apply(() => scopeAngular.apCtrl.changeAbrirWorkFlow(validateItensCheckList));

    return () => {};
  }, [validateItensCheckList, scopeAngular]);

  // HANDLE CHECK EACH ITEM VALIDATION
  const handleItem = useCallback((item, bool) => {
    const copyItensValidate = itensValidate.map(object => ({...object}));
    const index             = copyItensValidate.findIndex((ele) => ele.ordem === item.ordem);
    if (index >= 0) {
      copyItensValidate[index].status = bool;
      if (copyItensValidate[index].status !== itensValidate[index].status) {
        setItensValidate(copyItensValidate);
      }
    }
  }, [itensValidate]);

  const bodyItensCheckList = itensCheckList.map((item) => {
    const copyDefaultProps    = {...defaultProps};
    const itemIdx             = item.ordem + 1;
    const defaultFileListItem = copyDefaultProps.defaultFileList.filter(file => {
      const {check_list: {item}} = JSON.parse(file.extras);
      if (item === itemIdx) {
        return file;
      }
    });

    copyDefaultProps.defaultFileList = defaultFileListItem;

    return (
      <>
        {item.ordem !== 0 && <Divider className='m-1' dashed />}
        <ItemCheckList defaultProps={copyDefaultProps}
                       item={item}
                       handleItemOk={handleItem} />
      </>
    );
  });

  // RETURN CHECK LIST
  return <>
    <div className='card'>
      <div className='card-body p-1'>
        <div className='card-header text-center bg-dark text-white mb-1 border-0'>
          CHECKLIST COM {checkList.length} ITENS
        </div>
        {bodyItensCheckList}
      </div>
    </div>
  </>;
};

export default React.memo(CheckList);
