import * as Routes from '../../scripts/routes';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  OrgaosProcessosIndexController = ($http) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.sortColumnName    = 'sigla'
    vm.reverseColumnName = false


    # VARIAVEIS PRIVATE


    # WATCHES


    # INIT SETUP
    init = () =>
      return

    # METHODS PRIVATE


    # METHODS VIEW MODEL
    vm.sortColumn = (name) ->
      vm.reverseColumnName = if vm.sortColumnName == name then !vm.reverseColumnName else true;
      vm.sortColumnName    = name

      return

    vm.changeStatus = (orgao) ->
      if orgao.protocolo_id
        result = await Swal.fire(
          icon:                'warning'
          title:               'Atenção!'
          text:                "Deseja alterar o Órgão #{orgao.sigla} para o status #{!orgao.ativo && 'ATIVO' || 'INATIVO'}"
          confirmButtonText:   'SIM'
          confirmButtonColor:  '#075E54'
          showCloseButton:     true
          showCancelButton:    true
          showLoaderOnConfirm: true
          preConfirm: (_value) ->
            return new Promise((resolve) ->
              $http.get(Routes.change_orgao_protocolo_externo_orgao_processo_path(orgao.id, {habilitar: !orgao.ativo})).then ((result) ->
                resolve(result.status == 200)
              ), (error) ->
                Swal.fire("Erro!", error.data.message, "error")
              return
            )
          allowOutsideClick:   false
        )

        if result.value
          Turbolinks.visit(location.toString() + '?clear_cache=orgaos_ativos_cache')
      else
        result = await Swal.fire(
          icon:                'warning'
          title:               'Atenção!'
          text:                "Para mudar o status é preciso definir o protocolo do Órgão #{orgao.sigla}"
          confirmButtonText:   'Localizar Protocolos'
          confirmButtonColor:  '#075E54'
          showCloseButton:     true
          showCancelButton:    true
          showLoaderOnConfirm: true
          preConfirm: () ->
            new Promise((resolve) ->
              $http.get(Routes.protocolos_ativos_orgao_processo_path(orgao.id)).then ((result) ->
                if result.status == 200 then resolve(result.data) else resolve(false)

                return
              ), (error) ->
                Swal.fire("Erro!", error.data.message, "error")
              return
            )
          allowOutsideClick:   false
        )

        if result.value
          protocolos = {}
          protocolos[item.id] = item.sigla for item in result.value
          result = await Swal.fire(
            icon:                'warning'
            title:               'Atenção!'
            text:                "Selecione o protocolo do Órgão #{orgao.sigla}"
            input:               'select'
            inputOptions:        protocolos
            inputPlaceholder:    'Protocolos Ativos',
            confirmButtonText:   'Ativar Órgão'
            confirmButtonColor:  '#075E54'
            showCloseButton:     true
            showCancelButton:    true
            showLoaderOnConfirm: true
            preConfirm: (value) ->
              return new Promise((resolve) ->
                $http.get(Routes.change_orgao_protocolo_externo_orgao_processo_path(orgao.id, {
                  habilitar:    !orgao.ativo,
                  protocolo_id: value
                })).then ((result) ->
                  resolve(result.status == 200)
                ), (error) ->
                  Swal.fire("Erro!", error.data.message, "error")
                return
              )
            allowOutsideClick:   false
          )

          if result.value
            Turbolinks.visit(location.toString() + '?clear_cache=orgaos_processos')

      return

    vm.changeProtocolo = (orgao) ->
      result = await Swal.fire(
        icon:                'warning'
        title:               'Atenção!'
        text:                "Localizar protocolos cadastrados para o Órgão #{orgao.sigla}"
        confirmButtonText:   'Localizar Protocolos'
        confirmButtonColor:  '#075E54'
        showCloseButton:     true
        showCancelButton:    true
        showLoaderOnConfirm: true
        preConfirm: () ->
          new Promise((resolve) ->
            $http.get(Routes.protocolos_ativos_orgao_processo_path(orgao.id)).then ((result) ->
              if result.status == 200 then resolve(result.data) else resolve(false)

              return
            ), (error) ->
              Swal.fire("Erro!", error.data.message, "error")
            return
          )
        allowOutsideClick:   false
      )

      if result.value
        protocolos = {}
        protocolos[item.id] = item.sigla for item in result.value
        result = await Swal.fire(
          icon:                'warning'
          title:               'Atenção!'
          text:                "Selecione o protocolo do Órgão #{orgao.sigla}"
          input:               'select'
          inputOptions:        protocolos
          inputPlaceholder:    'Protocolos Ativos',
          confirmButtonText:   'Alterar Protocolo'
          confirmButtonColor:  '#075E54'
          showCloseButton:     true
          showCancelButton:    true
          showLoaderOnConfirm: true
          preConfirm: (value) ->
            return new Promise((resolve) ->
              $http.get(Routes.change_orgao_protocolo_externo_orgao_processo_path(orgao.id, {protocolo_id: value})).then ((result) ->
                resolve(result.status == 200)
              ), (error) ->
                Swal.fire("Erro!", error.data.message, "error")
              return
            )
          allowOutsideClick:   false
        )

        if result.value
          Turbolinks.visit(location.toString() + '?clear_cache=orgaos_processos')

      return


    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'OrgaosProcessosIndexController', OrgaosProcessosIndexController

  # ANGULAR INJECT DEPENDENCIES
  OrgaosProcessosIndexController.$inject = ['$http']

  # END BLOCK SCRIPT
  return
