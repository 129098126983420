import { API_BASE } from '../configs/Api';
import * as Routes from '../../scripts/routes';

export default class {
  static deletar     = async (id) => {
    const options = {
      method: 'DELETE',
      url:    Routes.notification_path(id)
    };

    return API_BASE(options);
  };

  static deletarIds  = async (ids) => {
    const options = {
      method: 'PUT',
      url:    Routes.deletar_ids_notifications_path(),
      data:   {ids}
    };

    return API_BASE(options);
  };

  static destroyAll  = async () => {
    const options = {
      method: 'PUT',
      url:    Routes.destroy_all_notifications_path()
    };

    return API_BASE(options);
  };

  static marcar_lida = async (id) => {
    const options = {
      method: 'PUT',
      url:    Routes.marcar_lida_notification_path(id)
    };

    return API_BASE(options);
  };
}
