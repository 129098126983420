import { API_BASE } from '../configs/Api';
import * as Routes from '../../scripts/routes';

export default class {
  static deletar = async (id) => {
    const options = {
      method: 'DELETE',
      url:    Routes.documento_path(id),
    };

    return API_BASE(options);
  };
}
