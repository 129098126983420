import React from 'react';
import {
  BarChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Tooltip,
  Legend
} from 'recharts';

export default ({chart_data = [], label = null}) => (
  <div style={{width: '100%', height: 400}}>
    <ResponsiveContainer>
      <BarChart data={chart_data}
                margin={{top: 15, right: 30, left: 20, bottom: 15}}
      >
        <CartesianGrid strokeDasharray={'4 4'}/>
        <YAxis label={{value: 'total', angle: -90, position: 'insideLeft'}}/>
        <XAxis dataKey={'dia'}/>
        <Tooltip/>
        <Legend/>
        <Bar dataKey={'quantidade'} fill={'#075E54'}>
          <LabelList dataKey={(item) => `${item.quantidade} ${label}`} position={'top'}/>
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  </div>
);
