import React from 'react';
import { Empty } from 'antd';

export default ({desc = null}) => {
  return (
    <>
      <Empty description={<span className={'lead text-muted'}>{desc}</span>} />
    </>
  );
};
