import React from 'react';
import { Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as Routes from '../../../scripts/routes';
import ProcessoService from '../../services/ProcessoService';

export default ({desc = null, hashid}) => {

  const handleOk = async () => {
    try {
      const response = await ProcessoService.deletar(hashid);
      if (response.status === 200) {
        Turbolinks.visit(Routes.usuario_processos_path({message: 'Rascunho removido'}));
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
    }
  };

  const handleClick = () => {
    Modal.confirm({
      title:    'Atenção',
      icon:     <ExclamationCircleOutlined/>,
      zIndex:   3100,
      content:  (<>
        <h5 className={'text-danger'}>Apagar Item {desc}</h5>
      </>),
      okText:   'Sim, Deletar',
      centered: true,
      onOk:     handleOk,
    });
  };

  return (
    <div onClick={handleClick}>
      <i className='fa fa-trash'></i> apagar
    </div>
  );
};
