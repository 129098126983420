import React from 'react';
import { Modal, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import UsuariosService from '../../services/UsuariosService';

export default (hashid, manifestante_com_multiacesso, callback = () => {}) => {
  const _handleOk = async () => {
    try {
      const response = await UsuariosService.permissao_multiusuarios(hashid);
      if (response.status === 200) {
        const {id} = response.data;
        notification.success({
          message:     'Sucesso',
          description: 'Usuário atualizado com sucesso',
          duration:    10,
          placement:   'bottomRight'
        });
        callback(true);
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
      callback(false);
    }
  };

  return Modal.confirm({
    title:    'Atenção',
    icon:     <ExclamationCircleOutlined/>,
    zIndex:   3100,
    content:  (<>
      {console.log(manifestante_com_multiacesso)}
      <div>{`${manifestante_com_multiacesso ? 'Desabilitar' : 'Habilitar'} Multiusuários do Manifestante`}</div>
    </>),
    okText:   'Continuar',
    centered: true,
    onOk:     _handleOk,
    onCancel() {callback(false)},
  });
};
