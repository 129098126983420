import {API_BASE} from '../configs/Api';
import * as Routes from '../../scripts/routes';

export default class {
  static orgaos_externos_ativos = async () => {
    const options = {
      method: 'GET',
      url:    Routes.lotacoes_orgaos_externos_ativos_path(),
      cache:  {
        maxAge: 2 * 60 * 1000
      }
    };

    return API_BASE(options);
  };

  static add_orgaos_blocked_by_assunto = async (id, params) => {
    const options = {
      method: 'POST',
      url:    Routes.add_orgaos_blocked_by_assunto_tipo_assunto_processo_path(id),
      data:   params
    };

    return API_BASE(options);
  };

  static remove_orgaos_blocked_by_assunto = async (id, params) => {
    const options = {
      method: 'DELETE',
      url:    Routes.remove_orgaos_blocked_by_assunto_tipo_assunto_processo_path(id),
      data:   params
    };

    return API_BASE(options);
  };
}
