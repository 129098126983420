import React from 'react';
import UploadFiles from './UploadFiles';
import _ from 'lodash';
import CheckList from './CheckList';

export default (props) => {
  // FILTER PROPS
  const {workflowDocumentos, defaultProps} = props;

  // RETURN CHECKLIST OR UPLOAD FILES
  return (
    _.isEmpty(workflowDocumentos) ? (
      <UploadFiles {...defaultProps} />
    ) : (
      <CheckList {...props} />
    )
  );
};
