import { Alert, Button, Divider, Empty, notification, Spin, Tag, Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import ProcessoService from '../../services/ProcessoService';

const TimelineSolicitacoes = ({dataTimeline = []}) => {
  if (_.isEmpty(dataTimeline)) {
    return null;
  }

  let body = [];
  dataTimeline.forEach((ele) => {
    if (!_.isEmpty(ele.solicitacao)) {
      const {data, mensagem} = ele.solicitacao;
      body.push(
        <Timeline.Item dot={<ClockCircleOutlined style={{fontSize: '18px'}}/>} color="red">
          <div><strong>SOLICITAÇÃO | {data}</strong></div>
          <Alert
            className={'text-justify'}
            description={
              <span>
              <strong>Mensagem: </strong>
                {mensagem}
            </span>
            }
            type="error"
          />
        </Timeline.Item>
      );
    }
    if (!_.isEmpty(ele.resposta)) {
      const {data, mensagem, documentos} = ele.resposta;
      body.push(
        <Timeline.Item dot={<ClockCircleOutlined style={{fontSize: '18px'}}/>}>
          <div><strong>{data} | SUA RESPOSTA</strong></div>
          <Alert
            className={'text-justify'}
            description={
              <span>
              <p>
                <strong>Mensagem: </strong>
                {mensagem}
              </p>
                {!_.isEmpty(documentos) && (
                  <p>
                    <strong>Documentos: </strong>
                    {documentos.map((doc, idx) => (<Tag color="default" key={idx}>{doc}</Tag>))}
                  </p>
                )}
            </span>
            }
            type="success"
          />
        </Timeline.Item>
      );
    }
  });

  return (
    <Timeline mode="alternate" reverse>
      {body.map((ele) => <span key={_.uniqueId()}>{ele}</span>)}
    </Timeline>
  );
};

export default ({hashid}) => {
  const [loading, setLoading]           = useState(false);
  const [dataTimeline, setDataTimeline] = useState([]);
  const [showError, setShowError]       = useState(false);

  useEffect(() => {
    const fetchSolicitacoes = async () => {
      setShowError(false);
      setLoading(true);
      try {
        const {data} = await ProcessoService.solicitacoes(hashid);
        setDataTimeline(data.results);
      } catch (e) {
        setShowError(true);
      }
      setLoading(false);
    };

    fetchSolicitacoes().then();
  }, [hashid]);

  return (
    <>
      <Divider dashed>Histórico de Solicitações</Divider>
      {showError ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
               description={<div className="lead text-danger">Desculpe, houve um erro no processamento.</div>}/>
      ) : (
        <div className="container pr-4">
          {loading ? (
            <div className={'text-center my-5'}>
              <div className="lead"><Spin size="large"/> Carregando...</div>
            </div>
          ) : (
            <TimelineSolicitacoes dataTimeline={dataTimeline}/>
          )}
        </div>
      )}
    </>
  );
};
