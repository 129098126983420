import React, { useState, useEffect } from 'react';
import consumer from '../../channels/consumer';

export default (channel) => {
  const [connected, setConnected]       = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [received, setReceived]         = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    consumer.subscriptions.create({channel: channel}, {
      connected:    () => { (isSubscribed ? setConnected(true) : null); },
      disconnected: () => { (isSubscribed ? setDisconnected(false) : null); },
      received:     (data) => { (isSubscribed ? setReceived(data) : null); }
    });
    return () => (isSubscribed = null);
  }, []);

  return [connected, disconnected, received];
};
