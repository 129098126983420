import React, { useState } from 'react';
import { notification, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import ProcessoService from '../../services/ProcessoService';
import * as Routes from '../../../scripts/routes';

const {Option} = Select;

export default () => {
  const [data, setData]         = useState([]);
  const [value, setValue]       = useState([]);
  const [fetching, setFetching] = useState(false);

  const _onSearch = (value) => {
    if (value) {
      setData([]);
      setFetching(true);
      ProcessoService
        .search_by_numero(value)
        .then((result) => result.data)
        .then((data) => {
          setData(data);
          setFetching(false);
        })
        .catch(error => {
          setFetching(false);
          notification.info({
            message:     'Erro',
            description: `${error}`,
            duration:    10,
            placement:   'bottomRight'
          });
        });
    }
  };


  const _onChange = (value) => {
    setData([]);
    setFetching(false);
    setValue(value);
  };

  const _onSelect = (item) => {
    Turbolinks.visit(Routes.usuario_processos_path({numero: item.key}));
  };

  return (
    <>
      <Select
        showSearch
        allowClear
        labelInValue
        value={value}
        placeholder='Digite o número do processo aqui'
        notFoundContent={fetching ? <Spin size='small'/> : null}
        filterOption={false}
        onChange={_onChange}
        onSearch={debounce(_onSearch, 800)}
        onSelect={_onSelect}
        style={{width: '100%'}}
        size={'large'}
      >
        {data.map(d => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>
    </>
  );
};
