import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Menu, Dropdown, Button, ConfigProvider, Input, notification, Space, Switch, Table, Modal } from 'antd';
import ptBr from 'antd/es/locale/pt_BR';

import debounce from 'lodash/debounce';
import Highlighter from 'react-highlight-words';
import _ from 'lodash/lodash';

import UsuariosService from '../../services/UsuariosService';
import * as Routes from '../../../scripts/routes';
import aprovarCadastroUsuario from '../Modal/aprovarCadastroUsuario';
import permissaoMultiusuarios from '../Modal/permissaoMultiusuarios';
import deletarUsuario from '../Modal/deletarUsuario';
import useActionCable from '../../hooks/useActionCable';

const paginationOptions = {
  pageSizeOptions: ['25', '50'],
  showSizeChanger: true,
  showTotal:       (total, range) => (
    <Button type='dashed'>
      {range[0]}-{range[1]} de {total}
    </Button>)
};

const Usuarios = ({pageSize = 25, filters}) => {
  const searchInput                             = useRef(null);
  const [dataTable, setDataTable]               = useState([]);
  const [pagination, setPagination]             = useState({current: 1, pageSize: pageSize});
  const [loading, setLoading]                   = useState(false);
  const [searchText, setSearchText]             = useState('');
  const [searchedColumn, setSearchedColumn]     = useState('');
  const [connected, disconnected, received]     = useActionCable('NotificationChannel');
  const [canCreate, setCanCreate]               = useState(false);
  const [modalResendEmail, setModalResendEmail] = useState({
    ModalText:      '',
    visible:        false,
    confirmLoading: false,
    hashid:         null
  });

  const _fetchUser = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        const response                      = await UsuariosService.data_table(params);
        const {rows, totalCount, canCreate} = response.data;
        setDataTable(rows);
        setCanCreate(canCreate);
        setPagination({...paginationOptions, ...params.pagination, total: totalCount});
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
      setLoading(false);
    },
    []
  );

  const _reloadTable = useCallback((result) => {
    if (result) {
      _fetchUser({pagination});
    }
  }, [_fetchUser]);

  useEffect(() => {
    let params = {pagination};
    if (!_.isEmpty(filters)) {
      params = {...params, filters};
    }
    _fetchUser(params);
  }, [_fetchUser]);

  useEffect(() => {
    if (received?.data?.model == 'User') {
      _reloadTable(true);
    }
  }, [received, _reloadTable]);

  const _handleTableChange = debounce((pagination, filters, sorter, extra) => {
    _fetchUser({
      pagination: {
        current:  pagination.current,
        pageSize: pagination.pageSize
      },
      filters,
      sort:       {
        field: sorter.field,
        order: sorter.order
      }
    });
  }, 300);

  const _handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const _handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const _openModalResendEmail = (hashid, email) => {
    const ModalText = `email: ${email}`;
    setModalResendEmail(old => ({...old, ...{ModalText, hashid, visible: true}}));
  };

  const _sendRequestResendEmail = async () => {
    setModalResendEmail(old => ({...old, confirmLoading: true}));
    try {
      const response = await UsuariosService.resend_email_confirmation(modalResendEmail.hashid);
      if (response.status === 200) {
        const {email} = response.data;
        notification.success({
          message:     `Sucesso`,
          description: `Email de confirmação reenviado para: ${email}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
    }
    setModalResendEmail(old => ({...old, ...{visible: false, confirmLoading: false}}));
  };

  const _handleChangeStatus = debounce(async (id) => {
    setLoading(true);
    try {
      const response = await UsuariosService.change_status(id);
      if (response.status === 200) {
        const {id, activated} = response.data;
        const status          = activated ? 'SIM' : 'NÃO';
        notification.success({
          message:     `ID: ${id} - Sucesso`,
          description: `Status alterado para - Ativado: ${status}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
    }
    setLoading(false);
  }, 300);


  const getColumnSearchProps = dataIndex => ({
    filterDropdown:                ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={searchInput}
          placeholder={`Localizar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => _handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => _handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{width: 90}}
          >
            Procurar
          </Button>
          <Button onClick={() => _handleReset(clearFilters)} size='small' style={{width: 90}}>
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon:                    filtered => <SearchOutlined/>,
    onFilter:                      (value, record) =>
                                     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render:                        text => {
      return (searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ));
    }
  });

  const columns = [
    {
      title:     <div className={'mt-1'}>CPF / CNPJ</div>,
      align:     'center',
      key:       'cpf_cnpj',
      dataIndex: 'cpf_cnpj',
      ...getColumnSearchProps('cpf_cnpj'),
      render:    (_, {id, cpf_cnpj, manifestante_com_multiacesso, manifestante}) => {
        if (manifestante === null) {
          return <div> - </div>;
        } else {
          return <>
            <div>{cpf_cnpj}</div>
            {
              manifestante.user_id === id ?
              manifestante_com_multiacesso &&
              <span className='badge badge-pill badge-primary'> Possui Multiusuários </span>
              :
              <span className='badge badge-pill badge-warning'> Usuário de Manifestante </span>
            }
          </>;
        }
      },
    },
    {
      title:     <div className={'mt-1'}>Perfil</div>,
      align:     'center',
      key:       'roles_mask',
      dataIndex: 'roles_mask',
      // width:     '9rem',
      sorter:    true,
      ...getColumnSearchProps('roles_mask'),
      render:    (record) => (record.map((ele) => (
        <span key={ele} className="badge badge-primary text-white p-1">{ele}</span>)))
    },
    {
      title:  <div className={'mt-1'}>Ativado</div>,
      align:  'center',
      key:    'activated',
      // width:  '7rem',
      render: ({activated: status, hashid, can: {change_status}}, source) => {
        return <Switch size={'small'}
                       disabled={!change_status}
                       onChange={(_) => _handleChangeStatus(hashid)}
                       checkedChildren='sim' unCheckedChildren='não'
                       defaultChecked={status}/>;
      },
      sorter: true
    },
    {
      title:     <div className={'mt-1'}>E-Mail</div>,
      align:     'center',
      key:       'email',
      dataIndex: 'email',
      sorter:    true,
      ...getColumnSearchProps('email')
    },
    {
      title:     <div className={'mt-1'}>Fase</div>,
      align:     'center',
      key:       'state',
      dataIndex: 'state',
      // width:     '9rem',
      render:    (_, {state, account_confirmed}) => {
        if (account_confirmed) {
          return <div>{state}</div>;
        } else {
          return <>
            <div>{state}</div>
            <div className={'small text-warning'}>conta não confirmada</div>
          </>;
        }
      },
      sorter:    true
    },
    {
      title:     <div className={'mt-1'}>Criação</div>,
      align:     'center',
      key:       'created_at',
      dataIndex: 'created_at',
      sorter:    true
    },
    {
      title:  'Ações',
      align:  'center',
      width:  '5rem',
      render: (record) => {
        return (
          <>
            <Dropdown placement={'bottomRight'} trigger={['click']} overlay={
              <Menu>
                {
                  record.can.resend_email_confirmation &&
                  <Menu.Item
                    onClick={() => _openModalResendEmail(record.hashid, record.email)}
                    icon={<SendOutlined/>}>
                    <span>Email Confirmação</span>
                  </Menu.Item>
                }
                {
                  record.can.aprovar_cadastro &&
                  <Menu.Item
                    onClick={() => aprovarCadastroUsuario(record.id, record.hashid, record.email, _reloadTable)}
                    icon={<CheckCircleOutlined/>}>
                    <span>Aprovar Cadastro</span>
                  </Menu.Item>
                }
                {
                  record.can.edit &&
                  <Menu.Item icon={<EditOutlined/>}>
                    <a data-remote
                       className={'d-inline-flex'}
                       style={{width: '100%'}}
                       href={Routes.edit_usuario_path(record.hashid)}
                       data-disable-with='Aguarde...'>Editar</a>
                  </Menu.Item>
                }
                {
                  record.can.show &&
                  <Menu.Item icon={<SearchOutlined/>}>
                    <a data-remote
                       className={'d-inline-flex'}
                       style={{width: '100%'}}
                       href={Routes.usuario_path(record.hashid)}
                       data-disable-with='Aguarde...'>Detalhes</a>
                  </Menu.Item>
                }
                {
                  record.account_confirmed && record.can_multiusuarios &&
                  <Menu.Item
                    onClick={() => permissaoMultiusuarios(record.hashid, record.manifestante_com_multiacesso, _reloadTable)}
                    icon={<UsergroupAddOutlined/>}>
                    <span>
                      {`${record.manifestante_com_multiacesso ? 'Desabilitar' : 'Habilitar'} Multiusuários Manifestante`}
                    </span>
                  </Menu.Item>
                }
                {
                  record.can.visitar_with && record.can.change_status && record.account_confirmed &&
                  <Menu.Item
                    icon={<UserSwitchOutlined/>}>
                    <a className={'d-inline-flex'}
                       style={{width: '100%'}}
                       href={Routes.visitar_with_usuario_path(record.hashid)}
                       data-disable-with='Aguarde...'>Entrar</a>
                  </Menu.Item>
                }
                {
                  record.can.destroy &&
                  <Menu.Item
                    onClick={() => deletarUsuario(record.id, record.hashid, record.email, _reloadTable)}
                    icon={<DeleteOutlined/>}>
                    <span>Deletar</span>
                  </Menu.Item>
                }
              </Menu>
            }>
              <Button icon={<i className='fas fa-user-cog'></i>}
                      type={'dashed'}
                      size={'large'}></Button>
            </Dropdown>
          </>
        );
      }
    }
  ];

  const TitleTable = () => {
    return (
      <div className={'d-flex justify-content-between'}>
        <h5 className={'mt-1 mb-0'}>Listagem de Usuários</h5>
        {canCreate && (
          <Button type='primary'
                  data-remote
                  href={Routes.new_usuario_path()}
                  icon={<PlusCircleOutlined/>}>CRIAR</Button>
        )}
      </div>
    );
  };

  return (
    <ConfigProvider locale={ptBr}>
      <Table
        title={() => <TitleTable/>}
        bordered
        columns={columns}
        rowKey={'id'}
        dataSource={dataTable}
        pagination={pagination}
        loading={loading}
        onChange={_handleTableChange}
        scroll={{y: '50vh'}}
      />

      <Modal
        title="Reenviar Email Confirmação"
        centered
        visible={modalResendEmail.visible}
        onOk={() => _sendRequestResendEmail()}
        okText={'Reenviar'}
        confirmLoading={modalResendEmail.confirmLoading}
        onCancel={() => !modalResendEmail.confirmLoading && setModalResendEmail(old => ({...old, visible: false}))}
      >
        <h5>{modalResendEmail.ModalText}</h5>
      </Modal>
    </ConfigProvider>
  );
};

export default Usuarios;
