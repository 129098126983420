import * as Routes from '../../scripts/routes';
import { notification } from 'antd';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  TelefonesSetoresController = ($http, $scope, $timeout) ->
    vm = @

    # CONSTANTS


    # VARIABLES VIEW MODEL
    vm.sortColumnName           = 'tree'
    vm.reverseColumnName        = false
    vm.current_orgao_id         = null
    vm.lotacoes_telefones_dados = null


    # VARIAVEIS PRIVATE


    # WATCHES


    # INIT SETUP
    init = () =>
      return


    # METHODS VIEW MODEL

    vm.sortColumn = (name) ->
      vm.reverseColumnName = if vm.sortColumnName == name then !vm.reverseColumnName else true;
      vm.sortColumnName    = name

    vm.addNumero = (lotacao) ->
      valor = lotacao.input_add.replace(/_/g, '')
      if valor.length > 13 && lotacao.numeros.indexOf(valor) == -1
        lotacao.numeros.push(valor)
        $timeout((-> mask_telefone()), 100)
        lotacao.input_add = ''

      return

    vm.removeNumero = (lotacao, index) ->
      lotacao.numeros.splice(index, 1);

      return

    vm.editNumero = (lotacao) ->
      lotacao.edit = !lotacao.edit

      return

    vm.cancelarEdit = (lotacao) ->
      lotacao.edit = false

      return

    vm.changeInputTelefone = (lotacao, item, index) ->
      lotacao.numeros[index] = item

      return

    vm.saveNumeros = (telefone_setor) ->
      email = (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(telefone_setor.email)
      if telefone_setor.id?
        $http.put(Routes.telefone_setor_path(telefone_setor.id), {
          numeros:  telefone_setor.numeros,
          setor_id: telefone_setor.setor_id,
          email:    telefone_setor.email
        }).then ((result) ->
          if result.status == 200
            telefone_setor.edit = false
            notification.success({
              message:     'Sucesso',
              description: 'Telefones Salvos',
              placement:   'bottomRight'
            })
          return
        ), (error) ->
          notification.error({
            message:     'Erro',
            description: error.data.message,
            duration:    10,
            placement:   'bottomRight'
          })
        return
      else
        if telefone_setor.numeros.length > 0 || email
          $http.post(Routes.telefones_setores_path(), {
            numeros:  telefone_setor.numeros,
            setor_id: telefone_setor.setor_id,
            email:    telefone_setor.email
          }).then ((result) ->
            if result.status == 200
              telefone_setor.id   = result.data.id
              telefone_setor.edit = false
              notification.success({
                message:     'Sucesso',
                description: 'Telefones Salvos',
                placement:   'bottomRight'
              })
            return
          ), (error) ->
            notification.error({
              message:     'Erro',
              description: error.data.message,
              duration:    10,
              placement:   'bottomRight'
            })
          return

        return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'TelefonesSetoresController', TelefonesSetoresController

  # ANGULAR INJECT DEPENDENCIES
  TelefonesSetoresController.$inject = ['$http', '$scope', '$timeout']

  # END BLOCK SCRIPT
  return
