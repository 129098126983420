do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  HomePageController = ($sce) ->
    vm = @

    # CONSTANTS

    # VARIABLES VIEW MODEL
    vm.modalTitle       = null
    vm.modalBody        = null
    vm.modalFooter      = null
    vm.modalBodyVideoId = null

    # VARIAVEIS PRIVATE

    # WATCHES

    # INIT SETUP
    init = () =>
      # alertModalParada()
      return

    # METHODS PRIVATE
    alertModalParada = ->
      Swal.fire
        showClass:
                             popup: 'animate__animated animate__zoomIn'
        hideClass:
                             popup: 'animate__animated animate__zoomOut'
        title:               'Atenção'
        html:                'No momento estamos com problemas no envio do <br /> e-mail de confirmação do cadastro para os e-mails Hotmail e Outlook. Caso seu processo seja urgente, utilize outro e-mail. Para maiores informações enviar e-mail para virtual@sepog.fortaleza.ce.gov.br.'
        icon:                'warning'
        closeOnEsc:          false
        closeOnClickOutside: false
        allowOutsideClick:   false
        allowEnterKey:       false
        dangerMode:          true
        confirmButtonText:   'ok, entendi'
        confirmButtonColor:  '#075E54'
      return

    # METHODS VIEW MODEL

    vm.openModalVideoAjuda = (title, id) ->
      modal = $('#modal-home-page-video-ajuda')
      modal.modal('show')
      vm.modalBodyVideoId = id
      vm.modalTitle       = title
      return

    vm.openModal = (menu, item) ->
      if item?.html?
        modal = $('#modal-home-page-footer')
        modal.modal('show')
        vm.modalTitle = "#{menu} - #{item.link}"
        vm.modalBody  = $sce.trustAsHtml(item.html)
      return

    vm.openModalCardTiposProcessos = ->
      modal = $('#modal-home-page-cards-tipos-processos')
      modal.modal('show')
      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'HomePageController', HomePageController

  # ANGULAR INJECT DEPENDENCIES
  HomePageController.$inject = ['$sce']

  # END BLOCK SCRIPT
  return
