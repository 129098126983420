import { DeleteOutlined } from '@ant-design/icons';
import {Form, Input, Modal, notification, Popconfirm, Switch, Tooltip, Tree, Transfer, Typography, ConfigProvider} from 'antd';
import _ from 'lodash/lodash';
import pluralize from 'pluralize';
import React, { useRef, useState } from 'react';
import ProcessoService from '../../services/ProcessoService';
import LotacoesService from '../../services/LotacoesService';
import ptBr from '../../../pt_BR';


function updateTreeData(list, key, children) {
  return list.map((node) => {
    if (node.key === key) {
      return {...node, children};
    } else if (node.children) {
      return {...node, children: updateTreeData(node.children, key, children)};
    }

    return node;
  });
}

const MODAL_ITEM_BASE = {visible: false, loading: false, title: 'title', body: 'body'};
const layout          = {
  labelCol:   {span: 5},
  wrapperCol: {span: 19}
};

const FormTranfer = ({orgaos, orgaosBlockeds = [], assuntoId = null}) => {
  const [targetKeys, setTargetKeys] = useState(orgaosBlockeds.map(String));
  const [selectedKeys, setSelectedKeys] = useState([]);
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
    setSelectedOrgaosBlockedsByAssunto(nextTargetKeys, direction, moveKeys);
  };

  const setSelectedOrgaosBlockedsByAssunto = async (nextTargetKeys, direction, moveKeys) => {
    // console.log('targetKeys:', nextTargetKeys);
    // console.log('direction:', direction);
    // console.log('moveKeys:', moveKeys);
    // add blocks
    const params = {orgaos_blocked: moveKeys};
    if (direction === 'right') {
      console.log('adds:', params);
      await LotacoesService.add_orgaos_blocked_by_assunto(assuntoId, params)
    }
    // remove blocks
    if (direction === 'left') {
      console.log('removes:', params);
      await LotacoesService.remove_orgaos_blocked_by_assunto(assuntoId, params)
    }
  }

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };


  return (

    <ConfigProvider locale={ptBr}>
      <Transfer
          listStyle={{width: '100%'}}
          dataSource={orgaos}
          titles={['Órgãos','Excluídos']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onChange}
          onSelectChange={onSelectChange}
          render={item => item.title}
          oneWay
      />
    </ConfigProvider>
  );
};

export default ({initTreeDate = [], orgaos=  []}) => {
  const [treeData, setTreeData]     = useState(loadTreeData(initTreeDate));
  const [modalItem, setModalItem]   = useState(MODAL_ITEM_BASE);
  const [orgaosData, setOrgaosData] = useState(orgaos);
  const form                        = useRef(null);
  const currentNode                 = useRef(null);

  const deletarNode = (node) => {
    ProcessoService
      .destroy_tipo(node.id)
      .then((result) => {
        if (result.status === 200) {
          setTreeData(_.filter(treeData, nodeData => nodeData.id !== node.id));
        }
      }).catch((e) => (
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      })
    ));
  };

  function configNodeTipo(node) {
    return {
      ...node,
      icon:  <span
               className={`badge badge-${node.parent_id ? 'warning' : 'primary'} text-uppercase text-white`}>{node.tipo_ou_assunto}</span>,
      title: (
               <div className='d-inline'
                    style={{marginLeft: `${node.parent_id ? '40px' : '15px'}`, lineHeight: '1.90'}}>
                 <Tooltip placement='bottomLeft' title={'clique com o botão direito para editar'}>
                   <strong className='mb-0'>
                     {node.nome}
                   </strong>
                   [<span
                   className={node.ativo ? 'text-success' : 'text-danger'}>{node.ativo ? 'ativo' : 'inativo'}</span>]
                   {node.externo && (
                     <span>[<span className={'text-info'}>externo</span>]</span>
                   )}
                   { (node.tipo_ou_assunto === 'assunto' && node?.orgaos_blocked?.length > 0) && (
                     <span>
                       [
                       <span className={'text-warning'}>
                         {node.orgaos_blocked.map((ele, idx) => <Typography.Text key={idx} type="danger" delete>{ele.sigla}, </Typography.Text>)}
                       </span>
                       ]
                     </span>
                   )

                   }
                   {node.tipo_ou_assunto === 'tipo' && (
                     <span>
                       [
                       <span className={'text-warning'}>
                         {pluralize('assunto', node.assuntos, true)}
                       </span>
                       ]
                     </span>
                   )}
                   {node.assuntos === 0 && (
                     <span>[<span className={'text-danger'}>
                       <Popconfirm title='Tem certeza disso？' okText='Sim' cancelText='Não'
                                   onConfirm={() => deletarNode(node)}>
                        <DeleteOutlined/>
                      </Popconfirm>
                     </span>]</span>
                   )}
                 </Tooltip>
               </div>
             )
    };
  }

  function loadTreeData(data) {
    return data.map((node) => configNodeTipo({...node, tipo_ou_assunto: 'tipo'}));
  }

  function onLoadData({key, children}) {
    return new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }

      ProcessoService.assuntos(key).then((result) => {
        if (result.status === 200) {
          setTreeData((origin) => updateTreeData(origin, key, result.data.map((node) => configNodeTipo({
            ...node,
            isLeaf:          true,
            parent_id:       key,
            tipo_ou_assunto: 'assunto'
          }))));
        }

        resolve();
      }).catch((e) => (
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        })
      ));
    });
  }

  const editItem = ({node}) => {
    currentNode.current = _.pick(node, ['id', 'nome', 'ativo', 'externo', 'sigla', 'tipo_ou_assunto', 'parent_id']);
    const isAssunto = node.tipo_ou_assunto === 'assunto'
    const orgaosBlockeds = node?.orgaos_blocked?.map(ele => ele.id)
    const body          = (<>
      <Form
        {...layout}
        name='control-hooks'
        initialValues={node}
        ref={form}
        preserve={false}
      >
        <Form.Item
          label='Sigla'
          name='sigla'
        >
          <Input placeholder={'Sigla'}/>
        </Form.Item>

        <Form.Item
          label='Nome'
          name='nome'
          rules={[{required: true, message: 'Por favor, digite o nome!'}]}
        >
          <Input placeholder={'Nome'}/>
        </Form.Item>

        <Form.Item label='Externo' name='externo' valuePropName='checked'>
          <Switch checkedChildren='ATIVO' unCheckedChildren='INATIVO'/>
        </Form.Item>

        <Form.Item label='Status' name='ativo' valuePropName='checked'>
          <Switch checkedChildren='ATIVO' unCheckedChildren='INATIVO'/>
        </Form.Item>
        {isAssunto && (
          <FormTranfer orgaos={orgaosData} orgaosBlockeds={orgaosBlockeds} assuntoId={node.id} />
        )}
      </Form>
    </>);
    setModalItem({...MODAL_ITEM_BASE, title: 'Editar', body, visible: true});
  };

  const updateItemFromModal = async () => {
    if (form.current.getFieldError('nome').length === 0 &&
      !_.isEqual(_.omit(currentNode.current, ['id']), form.current.getFieldsValue())) {
      setModalItem({...MODAL_ITEM_BASE, loading: true});
      const indexRoot =
              currentNode.current.parent_id ?
                treeData.findIndex(element => element.key === currentNode.current.parent_id) :
                treeData.findIndex(element => element.key === currentNode.current.id);
      let newData     = [...treeData];
      if (currentNode.current.tipo_ou_assunto === 'assunto') {
        const indexChildren                        =
                _.findIndex(newData[indexRoot].children, {id: currentNode.current.id});
        newData[indexRoot].children[indexChildren] =
          configNodeTipo({...newData[indexRoot].children[indexChildren], ...form.current.getFieldsValue()});
      } else {
        newData[indexRoot] = configNodeTipo({...newData[indexRoot], ...form.current.getFieldsValue()});
      }

      try {
        const response =
                currentNode.current.tipo_ou_assunto === 'assunto' ?
                  (await ProcessoService.editar_assunto(currentNode.current.id, form.current.getFieldsValue())) :
                  (await ProcessoService.editar_tipo(currentNode.current.id, form.current.getFieldsValue()));
        if (response.status === 200) {
          Swal.fire({
            icon: 'info',
            title: 'Processando alteração',
            text: 'Por favor aguarde um momento...',
            showCloseButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,

          });
          document.location.reload(true);
          setTreeData(newData);
          setModalItem(MODAL_ITEM_BASE);
        }
      } catch (e) {
        notification.info({
          message:     'Erro',
          description: `${e}`,
          duration:    10,
          placement:   'bottomRight'
        });
      }
    }
  };

  return (
    <>
      <Tree
        showLine={{showLeafIcon: false}}
        showIcon
        onRightClick={editItem}
        loadData={onLoadData}
        treeData={treeData}/>

      <Modal
        title={modalItem.title}
        mask={false}
        visible={modalItem.visible}
        confirmLoading={modalItem.loading}
        onCancel={() => !modalItem.loading && setModalItem(MODAL_ITEM_BASE)}
        cancelText={'Cancelar'}
        onOk={updateItemFromModal}
        okText={'Editar'}
        destroyOnClose
      >
        {modalItem.body}
      </Modal>
    </>
  );
};
