import React, { useState, useEffect } from 'react';
import UploadFiles from './UploadFiles';

const ItemCheckList = ({item, defaultProps, handleItemOk}) => {
  // CONFIG PROPS
  const {nome, quantidade, obrigatorio, ordem} = item;
  const requiredFiles                          = obrigatorio === 'true';
  defaultProps.requiredFiles                   = requiredFiles;
  defaultProps.maxFiles                        = quantidade;
  defaultProps.compOrder                       = ordem + 1;

  const [itemOk, setItemOk] = useState(defaultProps.requiredFiles === false);
  // EFFECT TO STATE UPLOAD ALL REQUIRED FILES
  useEffect(() => {
    if (handleItemOk) {
      handleItemOk(item, itemOk);
    }

    return () => {};
  }, [itemOk, handleItemOk]);

  const handleUploadAllRequiredFiles = (bool) => {
    setItemOk(bool);
  };

  // RETURN ITEM CHECK LIST
  return <>
    <ul className='list-group list-group-horizontal'>
      <li
        className='list-group-item p-0 d-flex align-items-center justify-content-center bg-dark text-white border-0'
        style={{width: '5%'}}>
        {ordem + 1}º
      </li>
      <li className='list-group-item p-0 border-0' style={{width: '95%'}}>
        <ul className='list-group list-group-horizontal'>
          <li className='list-group-item bg-dark text-white border-0 py-1' style={{width: '95%'}}>
            <div className='small'>ITEM</div>
            <div className='text-uppercase'>- {nome} -</div>
          </li>
          <li className='list-group-item bg-dark text-white border-0 py-1 text-center'>
            <div className='small'>QUANTIDADE</div>
            <div>- {quantidade} -</div>
          </li>
          <li className='list-group-item bg-dark text-white border-0 py-1 text-center'>
            <div className='small'>OBRIGATÓRIO:</div>
            <div className='text-uppercase'>- {requiredFiles ? 'sim' : 'nao'} -</div>
          </li>
        </ul>
        <div className='card'>
          <UploadFiles {...defaultProps} handleUploadAllRequiredFiles={handleUploadAllRequiredFiles} />
        </div>
      </li>
    </ul>
  </>;
};

export default React.memo(ItemCheckList);
