import * as Routes from '../../scripts/routes';
import debounce from 'lodash/debounce';

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  CadastroInicialController = ($http, $scope, $timeout) ->
    vm = @

    # CONSTANTS
    DATA_CEP = {
      uf:          null,
      cidade:      null,
      bairro:      null,
      endereco:    null,
      complemento: null
    }

    # VARIABLES VIEW MODEL
    vm.cep_com_bairro = true
    vm.cnpjIds         = null
    vm.form            = {
      tipo_manifestante_id: null,
      cnpj:                 null,
      organizacao:          null,
      telefone_comercial:   null,
      cpf:                  null,
      nome:                 null,
      sexo:                 null,
      telefone_celular:     null,
      email:                null,
      cep:                  null,
      DATA_CEP              ...
    }
    vm.loadSearchCep   = false
    vm.cepMessageError = ''
    vm.pessoa_fisica   = false

    # VARIAVEIS PRIVATE
    vm.cepSearched = null

    # WATCHES

    # WATCH TIPO MANIFESTANTE ID
    $scope.$watch (->
      vm.form.tipo_manifestante_id
    ), (current, _original) ->
      if current?
        configInputMasks()
      else
        vm.form.tipo_manifestante_id = null
      return

    # WATCH CEP
    $scope.$watch (->
      vm.form.cep
    ), (current, original) ->
      new_cep = current.replace(/[-_]/g, '') if current?
      old_cep = original.replace(/[-_]/g, '')  if original?
      if (old_cep? && old_cep.length == 8) && (vm.form.bairro == null || vm.form.endereco == null )
        vm.cep_com_bairro = false
        
      if (old_cep? && old_cep.length == 8) && (new_cep? && (new_cep.length < old_cep.length))
        vm.cep_com_bairro = true
        form = vm.form
        vm.form = {form..., DATA_CEP...}
      return

    # INIT SETUP
    init = ->

    # METHODS PRIVATE

    configInputMasks = ->
      if vm.form.tipo_manifestante_id
        $timeout (->
          vm.pessoa_fisica =
            vm.cnpjIds.includes(+vm.form.tipo_manifestante_id) == false
          if vm.pessoa_fisica
            mask_cpf()
            mask_telefone('.mask-telefone-celular')
            $('.select2-sexo').select2()
          else
            mask_cnpj()
            mask_telefone('.mask-telefone-comercial')
          return
        ), 100

    # METHODS VIEW MODEL

    vm.searchCep = debounce((() ->
      cep = vm.form.cep.replace(/[-_]/g, '')
      if cep? && cep.length == 8
        vm.loadSearchCep = true
        vm.cepSearched   = null
        vm.canEditCep    = false
        $http.get(Routes.services_search_cep_path(cep)).then ((result) ->
          vm.loadSearchCep   = false
          vm.cepMessageError = ''
          if result.status == 200
            vm.cepSearched = true

            if result.data.bairro == '' || result.data.endereco == ''
              vm.cep_com_bairro = false
            else
              vm.cep_com_bairro = true
            form = vm.form
            data_cep = result.data
            vm.form        = {form..., data_cep...}
          else
            vm.cepSearched     = false
            vm.cepMessageError = 'Cep não encontrado'
        ), (error) ->
          vm.cepMessageError = 'Problemas ao processar Cep, tente novamente'
          vm.loadSearchCep   = false
          vm.cepSearched     = false
          vm.canEditCep      = false
          console.log error
      return
    ), 300)

    vm.searchCPF = ->
      unless vm.form.cpf? && /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(vm.form.cpf)
        $('#user_manifestante_attributes_cpf').focus()
        return

      search_url = location.href.replace(/(cadastro_inicial)/, 'buscar_$1')
      uri = new URL(search_url)
      uri.searchParams.set('cpf', vm.form.cpf.replace(/\D/g, ''))

      $http.get(uri.href).then (result) ->
        for name, value of result.data
          vm.form[name] = value if vm.form[name] isnt undefined
        $('.select2-sexo').val(vm.form.sexo).select2()
        return
      .catch (error) ->
        console.error(error)
        return
      return

    vm.canSubmit = ->
      campos_cpf_cnpj = if vm.pessoa_fisica
        (
          vm.form.cpf &&
          vm.form.nome &&
          vm.form.sexo &&
          vm.form.telefone_celular.replace(/\D/g, '')
        )
      else
        (
          vm.form.cnpj &&
          vm.form.organizacao &&
          vm.form.telefone_comercial
        )
      (
        vm.form.tipo_manifestante_id &&
        campos_cpf_cnpj &&
        vm.form.email &&
        vm.form.cep &&
        vm.form.uf &&
        vm.form.cidade &&
        vm.form.bairro &&
        vm.form.endereco
      )

    # CALL INIT SETUP
    init()
    return

  # ANGULAR CONTROLLER
  angular.module('mainApp').controller('CadastroInicialController',
                                       CadastroInicialController)

  # ANGULAR INJECT DEPENDENCIES
  CadastroInicialController.$inject = ['$http', '$scope', '$timeout']

  # END BLOCK SCRIPT
  return
