import React, { useState, useEffect } from 'react';
import { notification, Upload, Modal } from 'antd';
import * as Routes from '../../../scripts/routes';
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import byte from 'bytes';
import DocumentoService from '../../services/DocumentoService';

// CONST EXTENSOES_VALIDAS FILES
const EXTENSOES_VALIDAS = {
  'doc':  'application/msword',
  'gif':  'image/gif',
  'png':  'image/png',
  'jpeg': 'image/jpeg',
  'jpg':  'image/jpeg',
  'odp':  'application/vnd.oasis.opendocument.presentation',
  'odt':  'application/vnd.oasis.opendocument.text',
  'pdf':  'application/pdf',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};

const UploadFiles = ({
                       defaultFileList = [],
                       formAuthenticityToken,
                       maxFiles = 5,
                       maxFileSize = '5MB',
                       acceptedFileTypes = ['pdf', 'jpg', 'png', 'gif'],
                       requiredFiles = false,
                       compOrder = 1,
                       handleUploadAllRequiredFiles

                     }) => {
  // CONFIG CONST
  const permittedFilesTypes                                 = Object.entries(EXTENSOES_VALIDAS).filter(item => acceptedFileTypes.includes(item[0])).map(item => item[1]);
  const [listFiles, setListFiles]                           = useState(defaultFileList);
  const [uploadAllRequiredFiles, setUploadAllRequiredFiles] = useState(requiredFiles === false);
  const [filenames, setFilenames]                           = useState([]);

  // EFFECT TO STATE UPLOAD ALL REQUIRED FILES
  useEffect(() => {
    if (requiredFiles === true) {
      if (listFiles.length > 0) {
        setUploadAllRequiredFiles(true);
      } else {
        setUploadAllRequiredFiles(false);
      }
    }

    return () => {};
  }, [requiredFiles, listFiles, maxFiles]);

  // EFFECT TO SEND HANDLE UPLOAD ALL REQUIRED FILES
  useEffect(() => {
    if (requiredFiles === true && handleUploadAllRequiredFiles) {
      handleUploadAllRequiredFiles(uploadAllRequiredFiles);
    }

    return () => {};
  }, [requiredFiles, uploadAllRequiredFiles, handleUploadAllRequiredFiles]);

  // VALIDATE FILE TYPE
  const validateFileType = (file) => {
    const isValidType = permittedFilesTypes.includes(file.type);
    if (!isValidType) {
      notification.error({
        message:     'Formato de arquivo não permitido',
        description: `${file.name}`,
        placement:   'bottomRight'
      });
    }
    return isValidType;
  };

  // VALIDATE FILE SIZE
  const validateFileSize = (file) => {
    const fileSize       = parseFloat(byte(file.size, {unit: 'MB'}).replace('MB', ''));
    const limiteFileSize = parseFloat(maxFileSize.replace('MB', ''));
    const isValidSize    = fileSize <= limiteFileSize;
    if (!isValidSize) {
      file.status = 'fileSizeLimite';
      notification.error({
        message:     `Tamanho de arquivo inválido`,
        description: `${file.name}`,
        placement:   'bottomRight'
      });
    }
    return isValidSize;
  };

  // CUSTOM ITEM LIST UPLOAD
  const customItemListUpload = (originNode, file, fileList) => {
    let icon         = <LoadingOutlined />;
    let errorMessage = null;
    let inputsHidden = null;
    let status       = null;
    let order = parseInt(fileList.findIndex(item => item.uid === file.uid));

    if (['done', 'novo', 'saved'].includes(file.status)) {
      const index = (compOrder * 1000) + parseInt(fileList.findIndex(item => item.uid === file.uid));
      if (file.status === 'saved') {
        status = <span className='text-info'>salvo em {file.created_at}</span>;
      } else {
        status = <span className='text-success'>novo</span>;
      }
      inputsHidden = (
        <>
          <input type='hidden'
                 name={`processo[movimentacao_attributes][documentos_attributes][${index}][${file?.hashid ? 'id' : 'arquivo_cache'}]`}
                 value={file.response} />

          <input type='hidden'
                 name={`processo[movimentacao_attributes][documentos_attributes][${index}][extras]`}
                 value={JSON.stringify({check_list: {item: compOrder}})} />
        </>
      );
      icon         = <CheckCircleTwoTone twoToneColor='#52c41a' />;
    }

    if (file.status === 'error') {
      status       = <span className='text-danger'>error</span>;
      errorMessage = (file.error?.status === 422 && 'arquivo corrompido') || 'erro no processamento';
      icon         = <CloseCircleTwoTone twoToneColor='#f5222d' />;
    }

    if (file.status === 'fileSizeLimite') {
      status       = <span className='text-danger'>error</span>;
      errorMessage = 'tamanho upload ultrapassado';
      icon         = <CloseCircleTwoTone twoToneColor='#f5222d' />;
    }

    if (file.status === 'RepeatedFileName') {
      status       = <span className='text-danger'>error</span>;
      errorMessage = 'arquivo com mesmo nome';
      icon         = <CloseCircleTwoTone twoToneColor='#f5222d' />;
    }

    return (
      <div className='border p-2'>
        <div>{inputsHidden}</div>
        <div>{originNode}</div>
        <div className='small'>
          [{icon}] |
          [{byte(file.size)}]
          {status && <span> | [{status}]</span>}
          {errorMessage && <span> | [<span className='text-danger'>{errorMessage}</span>]</span>}
        </div>
        <div>Ordem: {order}</div>
      </div>
    );
  };

  // REMOVE FILE
  const removeFile = async (file) => {
    try {
      const response = await DocumentoService.deletar(file.uid);
      if (response.status === 200) {
        let index = filenames.indexOf(file.name);
        if(index !== -1) {
          filenames.splice(index, 1);
        }
        return true;
      }
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
      return false;
    }
  };

  // HANDLE ON REMOVE FILE
  const handleOnRemove = (file) => {
    if (file?.status === 'saved') {
      return new Promise((resolve, _reject) => (
        Modal.confirm({
          title:    'Atenção',
          icon:     <ExclamationCircleOutlined />,
          zIndex:   3100,
          content:  (<>
            <div>Deletar Arquivo: <span className='text-danger'>{file.name}</span></div>
          </>),
          okText:   'Sim, Deletar',
          centered: true,
          onOk:     async () => {
            const result = await removeFile(file);

            resolve(result);
          }
        })
      )).then(result => result);
    } else {
      let index = filenames.indexOf(file.name);
      if(index !== -1) {
        filenames.splice(index, 1);
      }
      return true;
    }
  };

  // HANDLE ON CHANGE FILE UPLOAD
  const handleOnChange = (info) => {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      // message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      // message.error(`${info.file.name} file upload failed.`);
    }

    setListFiles(info.fileList);
  };

  // HANDLE BEFORE UPLOAD
  const handleBeforeUpload = (file) => {
    // VALIDATE TYPE
    const isValidType = validateFileType(file);

    // VALIDATE SIZE
    const isValidSize = validateFileSize(file);

    if(isValidType && isValidSize){
      if(filenames.includes(file.name)){
        file.status = "RepeatedFileName"
        notification.error({
          message:     'Já existe um arquivo com esse nome',
          description: `${file.name}`,
          placement:   'bottomRight'
        });
        filenames.push(file.name);
        return false;
      } else {
        filenames.push(file.name);
      }
    }

    return isValidType && isValidSize;
  };

  // CONFIG TO UPLOAD COMPONENT
  const configUpload = {
    defaultFileList,
    name:         'filepond',
    accept:       permittedFilesTypes.join(','),
    action:       Routes.documentos_path(),
    headers:      {'X-CSRF-Token': formAuthenticityToken},
    multiple:     true,
    maxCount:     maxFiles,
    progress:     {
      strokeColor: {'0%': '#108ee9', '100%': '#87d068'},
      strokeWidth: 3,
      format:      percent => `${parseFloat(percent.toFixed(2))}%`
    },
    beforeUpload: handleBeforeUpload,
    onChange:     handleOnChange,
    onRemove:     handleOnRemove,
    itemRender:   customItemListUpload
  };

  // BODY HTML TO UPLOAD COMPONENT
  const BodyHtmlUploadDragger = () => {
    return (
      <>
        <p className='ant-upload-drag-icon mb-1'>
          <DownloadOutlined />
        </p>
        <p className='ant-upload-text mb-0 text-primary'>
          Clique ou arraste o(s) documento(s) para esta área para fazer o upload
        </p>
        <p className='ant-upload-hint mb-0' style={{fontSize: '11px'}}>
          [limite de arquivos: <strong>{listFiles.length} de {maxFiles}</strong>] |
          [formatos aceitos: <strong>{acceptedFileTypes.map(item => `.${item}`).join(', ')}</strong>] |
          [limite de tamanho por arquivo: <strong>{maxFileSize}</strong>]
        </p>
        {
          requiredFiles && (
            <p className='ant-upload-hint mb-0 text-warning' style={{fontSize: '11px'}}>
              (obs.: São obrigatórios no mínimo 1 e no máximo {maxFiles} documentos)
            </p>
          )
        }
      </>
    );
  };

  // RETURN UPLOAD FILE
  return (
    <>
      <Upload.Dragger {...configUpload} className='p-0'>
        <BodyHtmlUploadDragger />
      </Upload.Dragger>
    </>
  );
};

export default React.memo(UploadFiles);
