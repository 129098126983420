import React from 'react';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

const dateFormat = 'DD/MM/YYYY';

export default ({inputName = null, defaultValue = null}) => {
  return (
    <DatePicker name={inputName}
                defaultValue={defaultValue && moment(defaultValue, dateFormat)}
                disabledDate={(current) => moment().add(6, 'day') > current}
                className={'form-control'}
                locale={locale}
                placeholder={'Selecione a Data'}
                format={dateFormat}/>
  );
};
