import bsCustomFileInput from 'bs-custom-file-input';
import AOS from 'aos';

import 'select2'
import 'magnific-popup/dist/jquery.magnific-popup'
import 'summernote/dist/summernote-bs4'
import 'summernote/lang/summernote-pt-BR'

do ->
  'use strict'

  $(document).on 'turbolinks:load', ->
    # LOAD TOOLTIPS
    $('[data-toggle="tooltip"]').tooltip()

    # MENU TOGGLE
    $('#menu-toggle').click (e) ->
      e.preventDefault();
      $('#wrapper').toggleClass 'toggled'
      return

    # SELECT2
    $.fn.select2.defaults.set('theme', 'bootstrap4');
    $.fn.select2.defaults.set('language', 'pt-BR');
    $('.select2').select2();

    # EDITOR SUMMERNOTE
    global.default_summernote = ->
      $('.summernote').summernote({
        disableDragAndDrop: true,
        height:             150,
        lang:               'pt-BR',
        toolbar:            [
          ['misc', ['undo', 'redo']],
          ['style', ['style', 'fontsize']],
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['style', ['forecolor', 'backcolor']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['table', 'hr', 'link']]
        ]
        callbacks: onChange: (contents) ->
          if $('.summernote').summernote('isEmpty')
            $('.summernote').next('.note-editor').find('.note-editable').html ''
          return
      });

    global.default_summernote()

    # FILE INPUT DYNAMIC
    bsCustomFileInput.init()

    # IMAGE POPUP
    $('.image-popup').magnificPopup({
      type: 'image',
      zoom: {
              enabled:  true,
              duration: 300
            }
    });

    # ANIMATE ON SCROLL
    AOS.init();

    return

  # END BLOCK SCRIPT
  return
