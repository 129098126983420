import React from 'react';
import { Skeleton } from 'antd';

export default () => {
  return (
    <>
      <ol className="breadcrumb shadow-lg">
        <li className="breadcrumb-item active">
          <strong><Skeleton.Button active size={'small'} style={{width: 100}}/></strong>
        </li>
        <li className="breadcrumb-item active">
          <strong><Skeleton.Button active size={'small'} style={{width: 100}}/></strong>
        </li>
      </ol>

      <div className="card border-0 shadow">
        <div className="card-header bg-white">
          <div className="d-flex justify-content-between">
            <h2 className="mb-0 text-muted">
              <Skeleton.Input active size={'large'} style={{width: 300}}/>
            </h2>
            <div>
              <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
              <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
              <span className="ml-1"><Skeleton.Avatar active size={'large'} shape={'square'}/></span>
            </div>
          </div>
        </div>
        <div className="card-body">
          <Skeleton paragraph={{rows: 4}} active/>
          <br/>
          <Skeleton paragraph={{rows: 4}} active/>
        </div>
      </div>
    </>
  );
};
