import ptBr from 'antd/es/locale/pt_BR';
import React, { useState, useEffect } from 'react';
import {
  Badge,
  Spin,
  Menu,
  Dropdown,
  Tooltip,
  Popconfirm,
  notification,
  Card,
  List,
  ConfigProvider
} from 'antd';
import useActionCable from '../../hooks/useActionCable';
import NotificationsService from '../../services/NotificationsService';
import { ClearOutlined } from '@ant-design/icons';
import * as Routes from '../../../scripts/routes';

export default ({defaultLista = [], refresh = {processos_usuarios: false}}) => {
  const [lista, setLista]                   = useState(defaultLista);
  const [visible, setVisible]               = useState(false);
  const [connected, disconnected, received] = useActionCable('NotificationChannel');
  const [loading, setLoading]               = useState(false);

  useEffect(() => {
    if (received) {
      if (refresh.processos_usuarios) {
        Turbolinks.visit(location.toString());
      } else {
        setLista((current => [{data: received.data}, ...current]));
      }
    }
  }, [received, refresh.processos_usuarios]);

  const _handleConfirm = async (id) => {
    try {
      setLoading(true);
      const response = await NotificationsService.deletar(id);
      if (response.status === 200) {
        const {id} = response.data;
        setLista(oldLista => oldLista.filter(item => item.id !== id));
      }
      setLoading(false);
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
      setLoading(false);
    }
  };

  const _handleDeletarIds = async () => {
    try {
      setLoading(true);
      const response = await NotificationsService.destroyAll();
      if (response.status === 200) {
        Turbolinks.visit(location.toString());
      }
      setLoading(false);
    } catch (e) {
      notification.info({
        message:     'Erro',
        description: `${e}`,
        duration:    10,
        placement:   'bottomRight'
      });
      setLoading(false);
    }
  };

  const menu = (
    <Menu style={{padding: '0px'}}>
      <li>
        <Spin spinning={loading} tip="Aguarde...">
          <Card size="small" title="Notificações" extra={
            lista.length > 1 &&
            <Tooltip placement={'left'} title={'apagar todos'}>
              <Popconfirm
                zIndex={3100}
                placement={'bottomRight'}
                title='Apagar todas as suas notificações?'
                onConfirm={() => _handleDeletarIds()}
                okText='Sim'
                cancelText='Não'>
                <ClearOutlined className={'text-danger mr-3'}/>
              </Popconfirm>
            </Tooltip>
          } style={{width: '30rem'}}>
            <ConfigProvider locale={ptBr}>
              <List
                itemLayout="horizontal"
                dataSource={lista}
                renderItem={item => {
                  let message = item.data?.message || 'nova notificação';
                  message     = Array.from(new Set(message.split(' '))).join(' ');

                  let actions = [];
                  if(item.tipo === 'notificacao_processo') {
                    if(item.notificador_hashid) {
                      actions.push(
                        <Tooltip placement={'top'} title={'ver processo'}>
                          <a href={Routes.usuario_processos_path() + `?numero=${item.notificador_hashid}`}
                             className={'mr-2 text-secondary'}><i
                            className='fas fa-share-square fa-flip-horizontal'/></a>
                        </Tooltip>
                      )
                    }
                  }
                  if(item.tipo === 'notificacao_user') {
                    if(item.notificador_id) {
                      actions.push(
                        <Tooltip zIndex={3100} placement={'top'} title={'ver usuário'}>
                          <a href={Routes.usuarios_path() + `?id=${item.notificador_id}`}
                             className={'mr-2 text-secondary'}><i
                            className='fas fa-share-square fa-flip-horizontal'/></a>
                        </Tooltip>
                      )
                    }
                  }
                  actions.push(
                    <Tooltip zIndex={3100} placement={'left'} title={'apagar mensagem'}>
                      <Popconfirm
                        zIndex={3100}
                        placement={'bottomRight'}
                        title='Apagar Mensagem?'
                        onConfirm={() => _handleConfirm(item.hashid)}
                        okText='Sim'
                        cancelText='Não'
                      >
                        <a className={'mr-2 text-warning'}><i className='fas fa-trash-alt'/></a>
                      </Popconfirm>
                    </Tooltip>
                  )

                  return (
                    <List.Item actions={actions}>
                      <List.Item.Meta
                        avatar={
                          <div className={'btn btn-link mr-2'} style={{width: '3em'}}>
                            {item.data?.icon && <i className={`${item.data.icon} fa-2x`}/> ||
                            <i className={`${item.data.icon || 'fas fa-exclamation-circle'} fa-2x`}/>}
                          </div>
                        }
                        title={<strong>{item.created_at}</strong>}
                        description={<div className={'text-uppercase'}>{message}</div>}
                      />
                    </List.Item>
                  );
                }}
              />
            </ConfigProvider>
          </Card>
        </Spin>
      </li>
    </Menu>
  );

  return (
    <>
      <div className={'nav-link d-inline p-1'} title={'NOTIFICAÇÕES'}>
        <Badge count={lista.length} overflowCount={5}>
          <Dropdown overlay={menu}
                    zIndex={3100}
                    trigger={'click'}
                    visible={visible}
                    onVisibleChange={setVisible}
                    placement={'bottomRight'}>
            <a className='btn btn-sm bg-white text-primary' onClick={e => e.preventDefault()}>
              <i className='fas fa-bell fa-2x'/>
            </a>
          </Dropdown>
        </Badge>
      </div>
    </>
  );
};
