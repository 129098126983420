import Inputmask from "inputmask";

do ->
  'use strict'
  # CPF
  global.mask_cpf = (element = '.mask-cpf') ->
    Inputmask({
      showMaskOnHover: false,
      mask:            '999.999.999-99',
    }).mask(element);

  # CNPJ
  global.mask_cnpj = (element = '.mask-cnpj') ->
    Inputmask({
      showMaskOnHover: false,
      mask:            '99.999.999/9999-99',
    }).mask(element);

  # CEP
  global.mask_cep = (element = '.mask-cep') ->
    Inputmask({
      showMaskOnHover: false
      mask:            '99999-999'
    }).mask(element);

  # TELEFONE
  global.mask_telefone = (element = '.mask-telefone') ->
    Inputmask({
      showMaskOnHover:           false
      mask:                      '(99) 9999[9]-9999'
      skipOptionalPartCharacter: ' '
    }).mask(element);

  # EMAIL
  global.mask_email = (element = '.mask-email') ->
    Inputmask(
      mask:   '*{1,40}[.*{1,40}][.*{1,40}][.*{1,40}]@*{1,40}[.*{2,40}][.*{1,40}][.*{1,40}][.*{1,40}][.*{1,40}]'
      greedy: false
      onBeforePaste: (pastedValue, opts) ->
        pastedValue = pastedValue.toLowerCase()
        pastedValue.replace 'mailto:', ''
      definitions:
              '*':
                validator: '[0-9A-Za-z!#$%&\'*+/=?^_`{|}~-]'
                casing:    'lower'
    ).mask(element);

  $(document).on 'turbolinks:load', ->
    mask_cpf();
    mask_cnpj();
    mask_cep();
    mask_telefone();
    mask_email();

    return
  return
