import { API_BASE } from '../configs/Api';
import * as Routes from '../../scripts/routes';

export default class {
  static deletar = async (id) => {
    const options = {
      method: 'DELETE',
      url:    Routes.processo_path(id)
    };

    return API_BASE(options);
  };

  static spue_movimentacoes = async (processo_id) => {
    const options = {
      method: 'GET',
      url:    Routes.spue_index_processo_movimentacoes_path(processo_id)
    };

    return API_BASE(options);
  };

  static spue_movimentacao_show = async (processo_id, movimentacao_id) => {
    const options = {
      method: 'GET',
      url:    Routes.processo_movimentacao_spue_show_path(processo_id, movimentacao_id)
    };

    return API_BASE(options);
  };

  static search_by_numero = (numero) => {
    const options = {
      method: 'GET',
      url:    Routes.search_by_numero_processos_path({numero})
    };

    return API_BASE(options);
  };

  static documentos = (id) => {
    const options = {
      method: 'GET',
      url:    Routes.documentos_processo_path(id)
    };

    return API_BASE(options);
  };

  static editar_tipo = async (id, params) => {
    const options = {
      method: 'POST',
      url:    Routes.editar_tipo_tipo_assunto_processo_path(id),
      data:   {tipo: params}
    };

    return API_BASE(options);
  };

  static destroy_tipo = async (id) => {
    const options = {
      method: 'DELETE',
      url:    Routes.destroy_tipo_tipo_assunto_processo_path(id)
    };

    return API_BASE(options);
  };

  static editar_assunto = async (id, params) => {
    const options = {
      method: 'POST',
      url:    Routes.editar_assunto_tipo_assunto_processo_path(id),
      data:   {assunto: params}
    };

    return API_BASE(options);
  };

  static assuntos = async (tipo_id) => {
    const options = {
      method: 'GET',
      url:    Routes.assuntos_tipo_assunto_processo_path(tipo_id)
    };

    return API_BASE(options);
  };

  static solicitacoes = async (id) => {
    const options = {
      method: 'GET',
      url:    Routes.solicitacoes_processo_path(id)
    };

    return API_BASE(options);
  };
}
