import introJs from 'intro.js'

do ->
  'use strict'

  # ANGULAR CONTROLLER FUNCTION
  TourGeralListagemProcessoController = (currentUserId, localStorageService, $timeout, $compile, $scope) ->
    vm = @

    # CONSTANTS

    # VARIABLES VIEW MODEL
    vm.introJs             = null
    vm.currentLocalStorage = {
      prefix: 'TourGeral.',
      suffix: "ListagemProcesso_#{currentUserId}",
      name: () -> "#{vm.currentLocalStorage.prefix}#{vm.currentLocalStorage.suffix}",
      value: () -> localStorageService.get(vm.currentLocalStorage.suffix),
      setValue: (bool) -> localStorageService.set(vm.currentLocalStorage.suffix, bool)
      init: () -> localStorageService.setPrefix(vm.currentLocalStorage.prefix)
    }

    # VARIAVEIS PRIVATE

    # WATCHES

    # INIT SETUP
    init = () =>
      initSetup()

      return

    # METHODS PRIVATE

    initSetup = ->
      vm.introJs = introJs()
      vm.currentLocalStorage.init()
      $timeout((-> initIntroJsTourGeral()), 500)

      return

    initIntroJsTourGeral = ->
      if vm.currentLocalStorage.value() != false
        setOptionsIntroJs()
        setCallbacksIntroJs()
        setStepsTourGeralIntroJs()
        setLocalStorageIntroJs(true)
        setStartIntroJs()

      return


    setOptionsIntroJs = ->
      vm.introJs = introJs() unless vm.introJs
      vm.introJs.setOptions({doneLabel: 'Fechar', prevLabel: 'Voltar', nextLabel: 'Próximo', skipLabel: 'Pular'})

      return

    setCallbacksIntroJs = ->
      vm.introJs.onchange ->
        $timeout((->
          div_intro_html = $('.nao-ver-mais-intro')
          div_intro_html.html($compile(div_intro_html.html())($scope))
        ), 500)

      vm.introJs.onexit ->
        vm.introJs = null
        $('.toggle-sticky-top').addClass('sticky-top')

      return

    setLocalStorageIntroJs = (bool = true) ->
      if vm.currentLocalStorage.value() == null
        vm.currentLocalStorage.setValue(true)
      else if vm.currentLocalStorage.value()
        vm.currentLocalStorage.setValue(bool)

      return

    setStartIntroJs = (skipLocalStorage = false) ->
      if skipLocalStorage || vm.currentLocalStorage.value()
        vm.introJs.start()
        $('.toggle-sticky-top').removeClass('sticky-top')

      return

    setStepsTourGeralIntroJs = (showLinkCancel = true) ->
      link_cancel = (showLinkCancel && "<span class='nao-ver-mais-intro'><small>(<a class='btn-link' ng-click='tglpCtrl.naoMostrarMaisTour()'><strong class='text-danger'>CLIQUE AQUI</strong></a> para <strong class='text-warning'>NÃO MOSTRAR</strong> o tour novamente)</small></span>") || ""
      steps = [
        {
          intro: """
            <div class='text-center'>
              <div class='lead'>Olá, notamos que você tem pelo menos um processo na sua listagem.</div>
              <div class='lead'>Vamos conhecer um pouco sobre suas informações?</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element:  '#step2-list-num',
          position: 'right',
          intro:    """
            <div class='text-center'>
              <div class='lead'>Aqui é o número do Rascunho ou Processo, com seu respectivo status.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step3-list-corpo',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Aqui estão as principais informações do seu Processo</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step4-list-corpo-tipo-assunto',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Tipo de Processo e Assunto</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step5-list-corpo-local-abertura',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Local para onde o Processo Virtual foi enviado na sua criação.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step6-list-corpo-local-atual',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Local atual da Prefeitura onde se encontra o Processo Virtual</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step7-list-corpo-data-criacao',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Data da criação Processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step8-list-corpo-documentos',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Documentos anexados ao Processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element: '#step89-list-corpo-solicitacoes',
          intro:   """
            <div class='text-center'>
              <div class='lead'>Solicitações enviadas ao Processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          element:  '#step9-list-acoes',
          position: 'left',
          intro:    """
            <div class='text-center'>
              <div class='lead'>Ações que você pode fazer no processo.</div>
              #{link_cancel}
            </div>
          """
        },
        {
          intro: """
            <div class='text-center'>
              <div class='lead'>Pronto, agora você conheceu mais algumas funcionalidades da Tela de Processos.</div>
              #{link_cancel}
            </div>
          """
        }
      ]
      vm.introJs.setOptions({steps})

      return


    # METHODS VIEW MODEL
    vm.naoMostrarMaisTour = () ->
      setLocalStorageIntroJs(false)
      vm.introJs.exit()

      return


    vm.showTourGeral = () ->
      setOptionsIntroJs()
      setStepsTourGeralIntroJs(false)
      setStartIntroJs(true)

      return

    # CALL INIT SETUP
    init()

    return


  # ANGULAR CONTROLLER
  angular.module('mainApp').controller 'TourGeralListagemProcessoController', TourGeralListagemProcessoController

  # ANGULAR INJECT DEPENDENCIES
  TourGeralListagemProcessoController.$inject = ['currentUserId', 'localStorageService', '$timeout', '$compile',
    '$scope']

  # END BLOCK SCRIPT
  return
